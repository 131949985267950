import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useGetWishlistByIdQuery } from './services/apifile'
import { getipToken, getToken, removecartToken, storecartToken } from './services/LocalStorageSrv'
const Minibolgwidth = ({ptitle, url, sellp, mrppr, prurl,productid,stock,weight,weighttype,height,breadth,length}) => {
  const getuserip = getipToken()
  const [cartvalue,setcartvalue] = useState({})
  let person 
  // console.log(height,breadth,length);
  const userdataed = getToken('data')
  if(getToken('data')){
    person = userdataed.user_id 
  }else{
    person = 0
  }

  const addwishlist = () => {
    const infodata = {
      pid: productid,
      userid: person,
      purl: prurl
    }
      axios("https://admin.ayurherbals.co/api/whishlist", {
        method: "POST",
        mode: "no-cors",
        data: JSON.stringify(infodata),
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
        },
      }).then((res) =>window.location.reload(false))}
      const delwishlist = () => {
        const infodata = {
          productid: productid,
          userid: person,
        }
        axios("https://admin.ayurherbals.co/api/deletewishlist", {
          method: "POST",
          mode: "no-cors",
          data: JSON.stringify(infodata),
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        }).then((res) =>window.location.reload(false))}
  const submitlogin = (e) => {
    let person 
    const userdataed = getToken('data')
    if(getToken('data')){
      person = userdataed.user_id 
    }else{
      person = 0
    }
    
    const infodata = {
      userip: getuserip.IPv4,
      productid: productid,
      quantity: 1,
      userid: person,
      weight:weight,
      weighttype:weighttype,
      proprice:mrppr,
      height:height,
      breadth:breadth,
      length:length,
    }
    e.preventDefault();
      axios("https://admin.ayurherbals.co/api/add-to-cart", {
        method: "POST",
        mode: "no-cors",
        data: JSON.stringify(infodata),
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
        },
      }).then((res) => setcartvalue(res));
  };
  const formdata = {
    person:person,
    productid:productid
  }
  const userdata = useGetWishlistByIdQuery(formdata);
  const superuser = userdata.data

    if (userdata.isLoading === true) {
      return <div></div>;
    } else {
  return (
    <div className="featcomp2">
            <div className={stock > 0 ? "resizeimage imgcomp2" : "resizeimage imgcomp2res" }>
      <NavLink to={`/product-details/${prurl}`} className="changeton">
              <img src={`https://admin.ayurherbals.co/upload/product/${url}`}  alt="showimg" className='con' />
            </NavLink>
            {person != 0 ? <div className="likebtn"  >
        {superuser.status == 1 ? <i class="fa-solid fa-heart Active" onClick={delwishlist}></i> : <i  className="fa-regular fa-heart " onClick={addwishlist}></i> }
            
          </div> : <div></div>}
          {/* {person != 0 ? superuser.status == 1 ? <div className="likebtn" onClick={addwishlist} ></div> : <div className="likebtn" onClick={deletewishlist} ></div> : <div></div>} */}
              
            </div>
            <div className="compdetails">
              <div className="comppara">
                
                <NavLink to={`/product-details/${prurl}`} className="changeton" ><h4> {`${ptitle.substr(0,25)}...`}</h4></NavLink>
              </div>

              <div className="price">
                <div className="pricehere">
                  <span className="pricewrite"> {`₹${mrppr}.00`}</span>
                </div>
               
              </div>
              <div className="comppara">
        <NavLink to={`/product-details/${prurl}`} className="changeton">
          <h6 style={{textAlign:"center",color:"#727271",margin:"0px",fontSize:"11px"}}>Price inclusive of all taxes</h6>
          </NavLink>
        </div>
            {cartvalue.status == 200 ? (
            <div>
               {cartvalue.data.status == 1 ? 
                removecartToken('cart')
              : 
                <div></div>
              }
              {cartvalue.data.status == 1 ? 
                storecartToken(cartvalue.data.data[0].incart)
              : 
                <div></div>
              }
              { cartvalue.data.status === 1 ? window.location.reload(false) : <div></div>}
               
              {cartvalue.data.status == 0 ? 
                <div>{alert("something went wrong try again")}</div>
              : 
                <div></div>
              }
              { cartvalue.data.status === 0 ? window.location.reload(false) : <div></div>}
            </div>
          ) : (
            <div></div>
          )}
            <form onSubmit={submitlogin} >
              <div className="addtocarthere">
                <button type={stock > 0 ? 'submit' : 'button' }>Add To Cart</button>

              </div>
              </form>
            </div>
          </div>
  )
          }
}

export default Minibolgwidth