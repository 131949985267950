import React from 'react'
import { useGetcontactinfoQuery } from '../services/apifile';

const Contactpart = () => {
    const contactinfo = useGetcontactinfoQuery();
    const superproduct = contactinfo.data;
    console.log("WERTYHGF",superproduct);
    if (contactinfo.isLoading === true) {
        return <div></div>;
      } else {
  return (
    <div className="contactleft">
          <h3>Contact Us</h3>
          {/* <p className="clp">
            
          </p> */}
          {/* <div className="clip">
         
         
            {" "}
            <i className="fa-solid fa-fax"></i>{" "}
            <p>Address : {superproduct.data[0].address}</p>
          </div> */}
          <div className="clip">
            {" "}
            <i className="fa-solid fa-phone"></i> <p><a className="uncolor" href={`mailto:${superproduct.data[0].email_id}`}>{superproduct.data[0].email_id}</a></p>
          </div>
          <div className="clip">
            {" "}
            <i className="fa-regular fa-envelope"></i> <p><a className="uncolor" href={`tel:${superproduct.data[0].phone_no}`}>{superproduct.data[0].phone_no}</a></p>
          </div>
        </div>
  )
      }
}

export default Contactpart