import React, { useEffect, useState } from "react";
import Footer from "./component/Footer";
import Header from "./component/Header";
import Lastfooter from "./component/Lastfooter";
import Menu from "./component/Menu";
import Scrolltotop from "./component/Scrolltotop";
import Socialmedia from "./component/Socialmedia";
import { Helmet } from "react-helmet";
import { couponcode, getcartToken, getcoupon, getipToken, getshipadd, getToken, removecartship, removeToken, storebillingToken } from "./services/LocalStorageSrv";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useGetAddressCheckQuery, useGetAllCheckoutReturnQuery } from "./services/apifile";
import Checkoutaddress from "./component/Checkoutaddress";
const Checkout = () => {
  const nvg = useNavigate()

  const shipaddon = getshipadd();
  let chechid;
  if(getshipadd()){
    chechid = shipaddon.address_id;
  }else{
      chechid  = null;
  }

  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current.focus();
  }, []);
  const userdataed = getToken("data");
  const name = userdataed.name.split(" ");
  const boxname = useRef("");
  const gtname = useRef("");
  const shipname = useRef("");
  const boxadd2 = useRef("");
  const boxcity = useRef("");
  const boxstate = useRef("");
  const boxcountry = useRef("");
  const boxzip = useRef("");
  const boxemail = useRef("");
  const boxnumber = useRef("");


  const autoaddone = useRef("");
  const autoaddtwo = useRef("");
  const autozip = useRef("");
  const autocity = useRef("");
  const autostate = useRef("");
  const autocountry = useRef("");
  const [wwe, setwwe] = useState(false);
  const [bname,setbname] = useState(name[0])
  const [blast,setblast] = useState(name[1])
  const [bsaddone,setbsaddone] = useState()
  const [bsaddtwo,setbsaddtwo] = useState()
  const [bcity,setbcity] = useState()
  const [bstate,setbstate] = useState()
  const [bcountry,setbcountry] = useState()
  const [bphone,setbphone] = useState(userdataed.phone)
  const [bemail,setbemail] = useState(userdataed.email)
  const [bpincode,setbpincode] = useState()
  const [subtoted,setsubtoted] = useState()
  const [shiping,setshiping] = useState()
  const [superresponse,setsuperresponse] = useState({})
  const [supertworesponse,setsupertworesponse] = useState({})


  const [twobname,twosetbname] = useState()
  const [twoblast,twosetblast] = useState()
  const [twobsaddone,twosetbsaddone] = useState()
  const [twobsaddtwo,twosetbsaddtwo] = useState()
  const [twobcity,twosetbcity] = useState()
  const [twobstate,twosetbstate] = useState()
  const [twobcountry,twosetbcountry] = useState()
  const [twobphone,twosetbphone] = useState()
  const [twobemail,twosetbemail] = useState()
  const [twobpincode,twosetbpincode] = useState()
const [formradio, setformradio] = useState({
  isAgree: false,
  paytype : "online pay"
})
removeToken('keys');
const handleradio = (event) =>{
const target = event.target
const name = target.name
const value = target.value
setformradio({
  ...formradio,
  [name] : value
})
}
  let couponc = getcoupon('couponid');
  let percoupon
  if (getcoupon('couponid')) {
    percoupon = couponc;
  } else {
    percoupon = 0;
  }
  let getall;
  if (getcoupon('couponid')) {
    getall = couponc;
  } else {
    getall = {"status":"0","code":"0","disamount":"0","subtotal":"0"};
  }
  let person;
  if (getToken("data")) {
    person = userdataed.user_id;
  } else {
    person = 0;
  }
  const userpinfo = getipToken();
  const userdata = useGetAllCheckoutReturnQuery(person);
  const transfer = userdata.data;

  const thisdata = {
    userip: userpinfo.IPv4,
    userid: person,
  };
  const [response, setresponse] = useState([]);
  const postspec = () => {
    axios("https://admin.ayurherbals.co/api/viewcartpost", {
      method: "POST",
      mode: "no-cors",
      data: JSON.stringify(thisdata),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    }).then((res) => {
      setresponse(res);
    });
  };

  const [supresponse, setsupresponse] = useState([]);
  const suppostspec = async () => {
     axios(`https://admin.ayurherbals.co/api/autofill`, {
      method: "Post",
      mode: "no-cors",
      data: JSON.stringify(thisdata),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    }).then((res) => {
      setsupresponse(res.data);
    });
  };


  useEffect(() => {
    postspec();
    suppostspec();

  }, []);
  // useEffect(()=>{
  //   const checkauot = useGetAddressCheckQuery(person);
  //   setsupedata(checkauot.data);
  // })
// console.log(bname);
// console.log('qwerty')
 const [tesval,settesval] = useState(true)
 let supervalue
 const Sandsuperdata = (e) =>{
    e.preventDefault();
    // console.log("sdfghjkjhgfdsasdfgh",gtname.current.value)
    // console.log("sdfghjkjhgfdsasdfgh",shipname.current.value)
    let newaddone;
    if(bsaddone != undefined){
    newaddone = bsaddone;
    }else{
      newaddone = autoaddone.current.defaultValue;
    }
    let newaddtwo;
    if(bsaddtwo != undefined){
    newaddtwo = bsaddtwo;
    }else{
      newaddtwo = autoaddtwo.current.defaultValue;
    }
    let newcity;
    if(bcity != undefined){
    newcity = bcity;
    }else{
      newcity = autocity.current.defaultValue;
    }
    let newstate;
    if(bstate != undefined){
    newstate = bstate;
    }else{
      newstate = autostate.current.defaultValue;
    }
    let newcountry;
    if(bcountry != undefined){
    newcountry = bcountry;
    }else{
      newcountry = autocountry.current.defaultValue;
    }
    let newzip;
    if(bpincode != undefined){
    newzip = bpincode;
    }else{
      newzip = autozip.current.defaultValue;
    }
   
   if(chechid === null){
    if(tesval == true){
      const longdataed = {
       bname:bname,
       blast:blast,
       baddress:newaddone,
       baddresstwo:newaddtwo,
       bcity:newcity,
       bpincode:newzip,
       bstate:newstate,
       bcountry:newcountry,
       bphone:bphone,
       bemail:bemail,
       userip: userpinfo.IPv4,
       userid: person,
       disamount: getall.disamount,
       couponcode: getall.code,
       shipping:shipname.current.value,
       subtotal:gtname.current.value,
       paymentmethod:formradio.paytype
      }
      if(formradio.paytype == "COD"){
       axios("https://admin.ayurherbals.co/api/billingdtls", {
         method: "POST",
         mode: "no-cors",
         data: JSON.stringify(longdataed),
         headers: {
           "Content-Type": "application/json; charset=UTF-8",
           "Access-Control-Allow-Origin": "*",
           Accept: "application/json",
         },
       }).then((res) => {
         storebillingToken(res.data);
         setsupertworesponse(res);
       });
       couponcode('',200)
      }else{
       axios("https://admin.ayurherbals.co/api/billingdtls", {
       method: "POST",
       mode: "no-cors",
       data: JSON.stringify(longdataed),
       headers: {
         "Content-Type": "application/json; charset=UTF-8",
         "Access-Control-Allow-Origin": "*",
         Accept: "application/json",
       },
     }).then((res) => {
       storebillingToken(res.data);
       setsuperresponse(res);
     });
     couponcode('',200)
      }
      
     setbname('')
     setblast('')
     setbsaddone('')
     setbsaddtwo('')
     setbcity('')
     setbcountry('')
     setbstate('')
     setbphone('')
     setbemail('')
     setbpincode('')
     twosetbname('')
     twosetblast('')
     twosetbsaddone('')
     twosetbsaddtwo('')
     twosetbcity('')
     twosetbcountry('')
     twosetbstate('')
     twosetbpincode('')
     twosetbphone('')
     twosetbemail('')
    }else{
 
 
     let newaddone;
     if(bsaddone != undefined){
     newaddone = bsaddone;
     }else{
       newaddone = autoaddone.current.defaultValue;
     }
     let newaddtwo;
     if(bsaddtwo != undefined){
     newaddtwo = bsaddtwo;
     }else{
       newaddtwo = autoaddtwo.current.defaultValue;
     }
     let newcity;
     if(bcity != undefined){
     newcity = bcity;
     }else{
       newcity = autocity.current.defaultValue;
     }
     let newstate;
     if(bstate != undefined){
     newstate = bstate;
     }else{
       newstate = autostate.current.defaultValue;
     }
     let newcountry;
     if(bcountry != undefined){
     newcountry = bcountry;
     }else{
       newcountry = autocountry.current.defaultValue;
     }
     let newzip;
     if(bpincode != undefined){
     newzip = bpincode;
     }else{
       newzip = autozip.current.defaultValue;
     }
     const longdata = {
       bname:bname,
       blast:blast,
       baddress:newaddone,
       baddresstwo:newaddtwo,
       bcity:newcity,
       bpincode:newzip,
       bstate:newstate,
       bcountry:newcountry,
       bphone:bphone,
       bemail:bemail,
       sname:twobname,
       slast:twoblast,
       saddress:twobsaddone,
       saddresstwo:twobsaddtwo,
       scity:twobcity,
       twobpincode:twobpincode,
       sstate:twobstate,
       scountry:twobcountry,
       sphone:twobphone,
       semail:twobemail,
       userip: userpinfo.IPv4,
       userid: person,
       disamount: getall.disamount,
       couponcode: getall.code,
       shipping:shipname.current.value,
       subtotal:gtname.current.value,
       paymentmethod:formradio.paytype
      } 
      if(formradio.paytype == "COD"){
      axios("https://admin.ayurherbals.co/api/billingdtls", {
       method: "POST",
       mode: "no-cors",
       data: JSON.stringify(longdata),
       headers: {
         "Content-Type": "application/json; charset=UTF-8",
         "Access-Control-Allow-Origin": "*",
         Accept: "application/json",
       },
     }).then((res) => {
       storebillingToken(res.data);
       setsupertworesponse(res);
     });
     couponcode('',200)
   }else{
     axios("https://admin.ayurherbals.co/api/billingdtls", {
       method: "POST",
       mode: "no-cors",
       data: JSON.stringify(longdata),
       headers: {
         "Content-Type": "application/json; charset=UTF-8",
         "Access-Control-Allow-Origin": "*",
         Accept: "application/json",
       },
     }).then((res) => {
       storebillingToken(res.data);
       setsuperresponse(res);
     });
     couponcode('',200) 
   }
     setbname('')
     setblast('')
     setbsaddone('')
     setbsaddtwo('')
     setbcity('')
     setbcountry('')
     setbstate('')
     setbphone('')
     setbemail('')
     setbpincode('')
     twosetbname('')
     twosetblast('')
     twosetbsaddone('')
     twosetbsaddtwo('')
     twosetbcity('')
     twosetbcountry('')
     twosetbpincode('')
     twosetbstate('')
     twosetbphone('')
     twosetbemail('')
    }
   }else{
    const newname = shipaddon.name.split(" ");
    let newaddone;
    if(bsaddone != undefined){
    newaddone = bsaddone;
    }else{
      newaddone = autoaddone.current.defaultValue;
    }
    let newaddtwo;
    if(bsaddtwo != undefined){
    newaddtwo = bsaddtwo;
    }else{
      newaddtwo = autoaddtwo.current.defaultValue;
    }
    let newcity;
    if(bcity != undefined){
    newcity = bcity;
    }else{
      newcity = autocity.current.defaultValue;
    }
    let newstate;
    if(bstate != undefined){
    newstate = bstate;
    }else{
      newstate = autostate.current.defaultValue;
    }
    let newcountry;
    if(bcountry != undefined){
    newcountry = bcountry;
    }else{
      newcountry = autocountry.current.defaultValue;
    }
    let newzip;
    if(bpincode != undefined){
    newzip = bpincode;
    }else{
      newzip = autozip.current.defaultValue;
    }
    const longdata = {
      bname:bname,
      blast:blast,
      baddress:newaddone,
      baddresstwo:newaddtwo,
      bcity:newcity,
      bpincode:newzip,
      bstate:newstate,
      bcountry:newcountry,
      bphone:bphone,
      bemail:bemail,
      sname:newname[0],
      slast:newname[1],
      saddress:shipaddon.address1,
      saddresstwo:shipaddon.address2,
      scity:shipaddon.city,
      twobpincode:shipaddon.zipcode,
      sstate:shipaddon.state,
      scountry:shipaddon.country,
      sphone:shipaddon.phone,
      semail:shipaddon.email,
      userip: userpinfo.IPv4,
      userid: person,
      disamount: getall.disamount,
      couponcode: getall.code,
      shipping:shipname.current.value,
      subtotal:gtname.current.value,
      paymentmethod:formradio.paytype
     } 
     if(formradio.paytype == "COD"){
     axios("https://admin.ayurherbals.co/api/billingdtls", {
      method: "POST",
      mode: "no-cors",
      data: JSON.stringify(longdata),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    }).then((res) => {
      storebillingToken(res.data);
      setsupertworesponse(res);
    });
    couponcode('',200)
  }else{
    axios("https://admin.ayurherbals.co/api/billingdtls", {
      method: "POST",
      mode: "no-cors",
      data: JSON.stringify(longdata),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    }).then((res) => {
      storebillingToken(res.data);
      setsuperresponse(res);
    });
    couponcode('',200) 
  }
    setbname('')
    setblast('')
    setbsaddone('')
    setbsaddtwo('')
    setbcity('')
    setbcountry('')
    setbstate('')
    setbphone('')
    setbemail('')
    setbpincode('')
    twosetbname('')
    twosetblast('')
    twosetbsaddone('')
    twosetbsaddtwo('')
    twosetbcity('')
    twosetbcountry('')
    twosetbpincode('')
    twosetbstate('')
    twosetbphone('')
    twosetbemail('')
   }
 }

  return (
    <div>
      <Socialmedia />
      <input type="text" ref={inputRef} style={{width:"0px",height:"0px",margin:"0px",padding:"0px",position:"absolute",top:"-6px",border:"none"}} />
      <Helmet>
        <title>CheckOut</title>
        <meta name='description' content='ayur Checkout page' />
        <meta name='keywords' content='herbal product, body care, face care' />
        
       </Helmet>
      <Header />
      <Scrolltotop />
      <Menu />
      {/* <Pagebredcame title="CHECKOUT" /> */}
      <div className="checkoutbody">
      <form onSubmit={(e) => Sandsuperdata(e)} >
        <div className="checkoutform">
          <div className="leftcheckout">
          {superresponse.status === 200 ? (
            <div>
              {superresponse.data.status === 1 ? 
                nvg('/nextstep')
              : 
                alert("something went wrong try again")
              }
              {/* { superresponse.data.status === 1 ? window.location.reload(false) : <div></div>} */}
            </div>
          ) : (
            <div></div>
          )}
          {supertworesponse.status === 200 ? (
            <div>
              {supertworesponse.data.status === 1 ? 
                   nvg('/thankyoupage')
              : 
                alert("something went wrong try again")
              }
              { supertworesponse.data.status === 1 ? removecartship() : <div></div>}
              {/* { supertworesponse.data.status === 1 ? window.location.reload(false) : <div></div>} */}
            </div>
          ) : (
            <div></div>
          )}
          
            <div className="lefttitle">
              <p>BILLING DETAILS</p>
            </div>
            <div className="twoinput">
              
                {supresponse.status == 'Success' ?<div className="firstlabel">
                <label htmlFor="onehere">First Name *</label> <input type="text" id="onehere" required value={bname} placeholder="Enter First Name" onChange={(e) => {
                    setbname(e.target.value.replace(/[^a-zA-Z]/gi, ""));
                  }} /> </div> : ''}

{supresponse.status == 'failed' ?<div className="firstlabel">
                <label htmlFor="onehere">First Name *</label> <input type="text" id="onehere" required value={bname} placeholder="Enter First Name" onChange={(e) => {
                    setbname(e.target.value.replace(/[^a-zA-Z]/gi, ""));
                  }} /> </div> : ''}
             
             {supresponse.status == 'Success' ? <div className="secondlabel">
                <label htmlFor="twohere">Last Name *</label>
                {response.status == 200 ? response.data.status == 1 ? <input type="text" id="twohere" required value={blast} placeholder="Enter Last Name" onChange={(e) => {
                    setblast(e.target.value.replace(/[^a-zA-Z\ ]/gi, ""),setsubtoted(response.data.withoutshipsubtotal),setshiping(response.data.shipcharge))
                  }} /> : "" : ""}
              </div> : ''}
              {supresponse.status == 'failed' ? <div className="secondlabel">
                <label htmlFor="twohere">Last Name *</label>
                {response.status == 200 ? response.data.status == 1 ? <input type="text" id="twohere" required value={blast} placeholder="Enter Last Name" onChange={(e) => {
                    setblast(e.target.value.replace(/[^a-zA-Z\ ]/gi, ""),setsubtoted(response.data.withoutshipsubtotal),setshiping(response.data.shipcharge));
                  }} /> : "" : ""}
              </div> : ''}
            </div>
            {supresponse.status == 'Success' ? <div className="companyname">
              <label htmlFor="company sparte">Street address *</label>
              <input
                type="text"
                id="company"
                ref={autoaddone}
                defaultValue={supresponse.data[0].address1}
                placeholder="House number and street name"
                required
                value={bsaddone}
                onChange={(e) => {
                  setbsaddone(e.target.value);
                }}
              />
              <input
                type="text"
                id="company"
                ref={autoaddtwo}
                defaultValue={supresponse.data[0].address2}
                placeholder="Apartment, suite, unit etc. (optional)"
                value={bsaddtwo}
                required
                onChange={(e) => {
                  setbsaddtwo(e.target.value);
                }}
              />
            </div> : <div></div>}


            {supresponse.status == 'failed' ? <div className="companyname">
              <label htmlFor="company sparte">Street address *</label>
              <input
                type="text"
                id="company"
                placeholder="House number and street name"
                required
                value={bsaddone}
                onChange={(e) => {
                  setbsaddone(e.target.value);
                }}
              />
              <input
                type="text"
                id="company"
                placeholder="Apartment, suite, unit etc. (optional)"
                value={bsaddtwo}
                required
                onChange={(e) => {
                  setbsaddtwo(e.target.value);
                }}
              />
            </div> : <div></div>}

            {supresponse.status == 'Success' ? <div className="companyname">
              <label htmlFor="torc">Pincode *</label>
              <input type="phone" ref={autozip} id="torc" defaultValue={supresponse.data[0].zipcode} value={bpincode} minLength={6} maxLength={6} placeholder="Enter City Pincode" required onChange={(e) => {
                    setbpincode(e.target.value.replace(/[^0-9]/gi, ""));
                  }} />
            </div> : <div></div>}

            {supresponse.status == 'failed' ? <div className="companyname">
              <label htmlFor="torc">Pincode *</label>
              <input type="phone" id="torc" value={bpincode} minLength={6} maxLength={6} placeholder="Enter City Pincode" required onChange={(e) => {
                    setbpincode(e.target.value.replace(/[^0-9]/gi, ""));
                  }} />
            </div> : <div></div>}

           {supresponse.status == 'Success' ? <div className="companyname">
              <label htmlFor="torc">City *</label>
              <input type="text" ref={autocity} id="torc" defaultValue={supresponse.data[0].city} value={bcity} placeholder="Enter City Name" required onChange={(e) => {
                    setbcity(e.target.value.replace(/[^a-zA-Z\ ]/gi, ""));
                  }} />
            </div> : <div></div>}


            {supresponse.status == 'failed' ? <div className="companyname">
              <label htmlFor="torc">City *</label>
              <input type="text" id="torc" value={bcity} placeholder="Enter City Name" required onChange={(e) => {
                    setbcity(e.target.value.replace(/[^a-zA-Z\ ]/gi, ""));
                  }} />
            </div> : <div></div>}

            {supresponse.status == 'Success' ? <div className="companyname">
              <label htmlFor="company">State *</label>
              <input type="text" ref={autostate} id="company" defaultValue={supresponse.data[0].state} required value={bstate} placeholder="Enter State Name" onChange={(e) => {
                    setbstate(e.target.value.replace(/[^a-zA-Z\ ]/gi, ""));
                  }} />
            </div> : <div></div>}

            {supresponse.status == 'failed' ? <div className="companyname">
              <label htmlFor="company">State *</label>
              <input type="text" id="company" required value={bstate} placeholder="Enter State Name" onChange={(e) => {
                    setbstate(e.target.value.replace(/[^a-zA-Z\ ]/gi, ""));
                  }} />
            </div> : <div></div>}

            {supresponse.status == 'Success' ? <div className="companyname">
              <label htmlFor="company">Country *</label>
              <input type="text" ref={autocountry} id="company" defaultValue={supresponse.data[0].country} required value={bcountry} placeholder="Enter Country Name" onChange={(e) => {
                    setbcountry(e.target.value.replace(/[^a-zA-Z\ ]/gi, ""));
                  }} />
            </div> : <div></div>}

            {supresponse.status == 'failed' ? <div className="companyname">
              <label htmlFor="company">Country *</label>
              <input type="text" id="company" required value={bcountry} placeholder="Enter Country Name" onChange={(e) => {
                    setbcountry(e.target.value.replace(/[^a-zA-Z\ ]/gi, ""));
                  }} />
            </div> : <div></div>}
            <div className="twoinput">
              <div className="firstlabel">
                <label htmlFor="onehere">Phone *</label>
                <input type="phone" id="onehere" required minLength={10} placeholder="Enter Phone Number" maxLength={10} value={bphone} onChange={(e) => {
                    setbphone(e.target.value.replace(/[^0-9]/gi, ""));
                  }} />
              </div>
              <div className="secondlabel">
                <label htmlFor="twohere">Email Address *</label>
                <input type="email" id="twohere" placeholder="Enter E-mail" required value={bemail} onChange={(e) => {
                    setbemail(e.target.value.replace(/[^A-Za-z0-9\ \@\_\.]/, ""));
                  }} />
              </div>
            </div>
            <div className="twoinput">
            <div >
              <input type="checkbox" name="tandc" id="term" required/>{" "}
             <label htmlFor="term"> I AGREE WITH THE TERMS AND CONDITIONS *</label>
            </div>
            </div>
            <div className="selectadd">
              {wwe === false ? <label
                className="updatecarting"
                style={{ color: "#333333",fontSize:'19px',marginTop:"20px" }}
                // onClick={() => setwwe(!wwe)}
              >
                {" "}
                Shipping Addresses
              </label> : ''}
            </div>
            { userdata.isLoading === true ? <div> </div> : wwe === false ? <Checkoutaddress data={transfer} name={boxname} /> : '' }
         {chechid === null ?             <div className="checkuser">
              <input type="checkbox" id="gogog" className="gogoe"  checked={wwe ? true : false } onClick={() => {settesval(!tesval);setwwe(!wwe)}}  />

              <label
                htmlFor="gogog"
                className="updatecarting"
                style={{ color: "#333333" }}
                // onClick={() => setwwe(!wwe)}
              >
                {" "}
                Ship To A Different Address?
              </label>
            </div> : ''}
            <div className={wwe ? "showform" : "showdiff"}>
              <div className="twoinput">
                <div className="firstlabel">
                  <label htmlFor="onehere">First Name *</label>
                  <input type="text" id="onehere" required={!tesval} placeholder="Enter First Name"  value={twobname} onChange={(e) => {
                    twosetbname(e.target.value.replace(/[^a-zA-Z]/gi, ""));
                  }}  />
                </div>
                <div className="secondlabel">
                  <label htmlFor="twohere">Last Name *</label>
                  <input type="text" id="twohere" required={!tesval} placeholder="Enter Last Name" value={twoblast} onChange={(e) => {
                    twosetblast(e.target.value.replace(/[^a-zA-Z\ ]/gi, ""));
                  }} />
                </div>
              </div>
              <div className="companyname">
                <label htmlFor="company sparte">Street address *</label>
                <input
                type="text"
                id="company"
                placeholder="House number and street name"
                required={!tesval}
                value={twobsaddone}
                onChange={(e) => {
                  twosetbsaddone(e.target.value);
                }} />
               <input
                type="text"
                id="company"
                placeholder="Apartment, suite, unit etc. (optional)"
                value={twobsaddtwo}
                required={!tesval}
                onChange={(e) => {
                  twosetbsaddtwo(e.target.value);
                }}
              />
              </div>
              <div className="companyname">
              <label htmlFor="torc">Pincode *</label>
              <input type="phone" id="torc" value={twobpincode} minLength={6} maxLength={6} placeholder="Enter City Pincode" required={!tesval} onChange={(e) => {
                    twosetbpincode(e.target.value.replace(/[^0-9]/gi, ""));
                  }} />
            </div>
              <div className="companyname">
                <label htmlFor="torc">City *</label>
                <input type="text" id="torc" required={!tesval} value={twobcity} placeholder="Enter City Name" onChange={(e) => {
                    twosetbcity(e.target.value.replace(/[^a-zA-Z\ ]/gi, ""));
                  }} />
              </div>
              <div className="companyname">
                <label htmlFor="company">State *</label>
                <input type="text" id="company" required={!tesval} placeholder="Enter State Name" value={twobstate} onChange={(e) => {
                    twosetbstate(e.target.value.replace(/[^A-Za-z\ ]/, ""));
                  }} />
              </div>
              <div className="companyname">
                <label htmlFor="company">Country *</label>
                <input type="text" id="company" required={!tesval} placeholder="Enter Country Name" value={twobcountry} onChange={(e) => {
                    twosetbcountry(e.target.value.replace(/[^A-Za-z\ ]/, ""));
                  }} />
              </div>
              <div className="twoinput">
                <div className="firstlabel">
                  <label htmlFor="onehere">Phone *</label>
                  <input type="phone" minLength={10} required={!tesval} maxLength={10} placeholder="Enter Phone Number" id="onehere" value={twobphone} onChange={(e) => {
                    twosetbphone(e.target.value.replace(/[^0-9]/gi, ""));
                  }} />
                </div>
                <div className="secondlabel">
                  <label htmlFor="twohere">Email Address *</label>
                  <input type="email" required={!tesval} id="twohere" placeholder="Enter E-mail" value={twobemail} onChange={(e) => {
                    twosetbemail(e.target.value.replace(/[^A-Za-z0-9\ \@\_\.]/, ""));
                  }} />
                </div>
              </div>
            </div>
            
          
          </div>
          {response.status == 200 ? 
                  response.data.status == 1 ? <div className="rightcheckout">
                  <div className="lefttitle">
                      <p>YOUR ORDER</p>
                    </div>
                    <div className="sperater">
                        <div className="rightsp">
                            <p>Product</p>
                        </div>
                        <div className="rightspr">
                            <p>Total</p>
                        </div>
                    </div>
                    {
                    response.data.data.map((item) => (
                      <div className="speraterclr" key={item.temp_id}>
                      <div className="rightsp" style={{borderRight:"1px solid #e5e5e5"}}>
                          <p>{`${item.product_title.substr(0,35)}... × ${item.quantity}`}</p>
                      </div>
                      <div className="rightspr">
                          <p style={{ fontFamily: "system-ui" }}>{`₹${item.price}.00`}</p>
                      </div>
                  </div>
                    ))

                    }
                    <div className="speraterclrmore">
                        <div className="rightsp" >
                            <p>Cart Subtotal</p>
                        </div>
                        <div className="rightspr">
                            <p style={{ fontFamily: "system-ui" }}>{`₹${response.data.withoutshipsubtotal}.00`}</p>
                        </div>
                    </div>
                    {percoupon != 0 ? <div className="speraterclrmore">
                        <div className="rightsp" >
                            <p>Coupon </p>
                        </div>
                        <div className="rightspr">
                            <p style={{ fontFamily: "system-ui" }}>{`- ₹${percoupon.disamount}.00`}</p>
                        </div>
                    </div> : ''}
                    <div className="speraterclrmore">
                        <div className="rightsp" >
                            <p>Shipping</p>
                        </div>
                        <div className="rightspr">
                            <p style={{ fontFamily: "system-ui" }}>{`₹${response.data.shipcharge}.00`}</p>
                            <input ref={shipname} type="hidden" name="" value={response.data.shipcharge} />
                        </div>
                    </div>
                    <div className="speraterclrmore">
                        <div className="rightsp" >
                            <p>Order Total</p>
                        </div>
                        <div className="rightspr">
                          {percoupon != 0 ? <p style={{ fontFamily: "system-ui" }}>{`₹${percoupon.subtotal}.00`}</p> : <p style={{ fontFamily: "system-ui" }}>{`₹${response.data.subtotal}.00`}</p>}
                          <input ref={gtname} type="hidden" name="" value={response.data.withoutshipsubtotal} />
                        </div>
                    </div>
                    <div className="setting">
                   {response.data.withoutshipsubtotal > 1500 ? <div><input type="radio" name="paytype" id="www" value="COD" onChange={handleradio} checked={formradio.paytype=="COD"} />
                    <label htmlFor="www" >COD</label></div> : '' } 
                   <div> <input type="radio" name="paytype" id="wwwe" value="online pay" onChange={handleradio} checked={formradio.paytype=="online pay"} />
                    <label htmlFor="wwwe" >Pay Online</label></div>
                    </div>
                    <div className="givebtn" ><button type="submit" className="updatecartineding" >Proceed</button></div>
                  </div> : (
                    <div></div>
                  )
               : (
                <div></div>
              )}
          
        </div>
        </form>
      </div>
      <Footer />
      <Lastfooter />
    </div>
  );
};

export default Checkout;
