import axios from 'axios'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Footer from './component/Footer'
import Header from './component/Header'
import Lastfooter from './component/Lastfooter'
import Menu from './component/Menu'
import Scrolltotop from './component/Scrolltotop'
import { getproductid, getToken } from './services/LocalStorageSrv'

const Lastpro = () => {
  const nvg = useNavigate()
  const supervalue = getToken("data").user_id;
  const [response,setresponse] = useState({})
  const [textfield,settextfield] = useState()
  const nav = useNavigate();
  const {id,pid} = useParams();
  const pinfo = getproductid('productid');

  const [formradio, setformradio] = useState({
    isAgree: false,
    paytype : "Return"
  })

  const handleradio = (event) =>{
  const target = event.target
  const name = target.name
  const value = target.value
  setformradio({
    ...formradio,
    [name] : value
  })
  }
    const submitlogin = (e) =>{
      e.preventDefault();
      // const collectdata = {
      //   merchant_id:'247583',
      //   order_id:'ORD000224',
      //   amount:100,
      //   currency: 'INR',
      //   refund_reason:'i change my mind',
      //   remarks:'just like that',
      //   access_code:'AVMI04JL51AS57IMSA',
      // }
      const collectdata = {
        user_id:supervalue,
        odr_id:id,
        address_id:pid,
        product_id:pinfo,
        type:formradio.paytype,
        reason:textfield
      }

      axios("https://admin.ayurherbals.co/api/returnorder", {
          method: "POST",
          mode: "no-cors",
          data: JSON.stringify(collectdata),
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        }).then((res) => {setresponse(res)});
     settextfield("")
    }
  return (
    <div>
        <Header />
      <Menu />
      <div className="logincompnet" style={{width:"100%"}}>
        <div style={{display:"flex",justifyContent:"center",textAlign:"center"}}>
          <h2>Return Reason</h2>
        </div>
        {
            response.status == 200 ? ( <div> { response.data.status == 1 ? alert("your order return successfully") : <div></div>}</div> ) : <div></div>
          }
          {
            response.status == 200 ? ( <div> { response.data.status == 1 ? nvg('/MyAccount/orders') : <div></div>}</div> ) : <div></div>
          }
          {
            response.status == 200 ? ( <div> { response.data.status == 1 ? window.location.reload(false) : <div></div>}</div> ) : <div></div>
          }
          {
            response.status == 200 ? ( <div> { response.data.status == 0 ? alert("Something went wrong try again") : <div></div>}</div> ) : <div></div>
          }
          {
            response.status == 200 ? ( <div> { response.data.status == 0 ? window.location.reload(false) : <div></div>}</div> ) : <div></div>
          }
        <form onSubmit={submitlogin} style={{width:"100%",display:"flex",justifyContent:"center"}} >
        <div className="loginformtoto">

          <div className="inputoneheretqqqq">
            <label htmlFor="detail" style={{fontWeight:"500",fontSize:'23px'}}>Reason </label>
            <textarea style={{border:"1px solid #e5e5e5",outline:"none",height:"90px"}} name="lpassword" value={textfield} id="detail" required 
                  onChange={(e) => {settextfield(e.target.value)}}
                  />
          </div>
          {/* <div className="setting">
                    <div><input type="radio" name="paytype" id="www" value="Return" onChange={handleradio} checked={formradio.paytype=="Return"} />
                    <label htmlFor="www" >Return</label></div>
                   <div> <input type="radio" name="paytype" id="wwwe" value="Exchange" onChange={handleradio} checked={formradio.paytype=="Exchange"} />
                    <label htmlFor="wwwe" >Exchange</label></div>
                    </div> */}
          <div className="bottomlog">
         <div className="loginbtn">
            <button className="updatecartined" type="submit">Submit</button>
         </div>
          </div>
        </div>
        </form>
      </div>
      <Scrolltotop />
      <Footer />
      <Lastfooter />
    </div>
  )
}

export default Lastpro