import React from 'react'
import { Helmet } from 'react-helmet'
import { NavLink } from 'react-router-dom'
import { useGetAllBradCrampQuery } from '../services/apifile'
const Pagebredcame = ({title,subtitle}) => {
    const resp = useGetAllBradCrampQuery(title)
    const superdata = resp.data
    if(resp.isLoading === true){return<div></div>}else{
  return (
    <div>
        <Helmet>
          <title>{superdata.data[0].pg_meta_title}</title>
          <meta name="description" content={superdata.data[0].pg_meta_desc} />
          <meta
            name="keywords"
            content={superdata.data[0].pg_meta_keyword}
          />
        </Helmet>
        <div className="bannerhere">
            <div className="bannerimg">
            <img src={`https://admin.ayurherbals.co/upload/${superdata.data[0].image}`} alt="304" />
            </div>
            {/* <div className="location">
                <p className='colorp'> <span style={{color:"#2A6233"}} className="fontwe">{title.toLowerCase()}</span></p>
                <p className='colorpad'> <span style={{color:"#2A6233"}} className="fontwed">{superdata.data[0].sort_description}</span></p>
            </div> */}
            {title == "special-kit" ?  <p className='superbread' style={{backgroundColor:"#f9f9f9",margin:"0px"}}><span><NavLink to='/' style={{color:"black",textDecoration:"none"}}>Home </NavLink> </span> &gt; <span>kits and Pouches</span></p> : <p className='superbread' style={{backgroundColor:"#f9f9f9",margin:"0px"}}><span><NavLink to='/' style={{color:"black",textDecoration:"none"}}>Home </NavLink> </span> &gt; <span>{superdata.data[0].pg_name}</span></p>}
        </div>
        {/* <div className="info">
            <div className="infotitle">
                <h1>Welcome To Alista Store!</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Provident ducimus id mollitia quisquam accusamus recusandae enim dolorem vitae laborum amet molestiae, molestias alias, neque impedit, assumenda corporis. Facere esse, error? Molestias explicabo voluptate, odit excepturi molestiae pariatur facilis facere, sunt laborum earum tenetur inventore! Error voluptatum iusto quidem officia, et omnis cupiditate rem, tenetur odit explicabo adipisci totam, eius?</p>
            </div>
        </div> */}
    </div>
  )
    }
}

export default Pagebredcame