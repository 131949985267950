import React, { useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import { Carousel } from "react-bootstrap";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useGetBannerImgQuery } from "../services/apifile";
// import video1 from "../assets/img/video1.m4v"
import first from "../assets/img/bg/breadcrmp.png";
import second from "../assets/img/blog/blog1.jpg"
import AOS from 'aos';
import 'aos/dist/aos.css'
import "./Customowlforbanner.css";
import { useRef } from "react";
const OwlCar = () => {
  const videoRef = useRef();
  const abcd = () =>{
videoRef.current.play();
  };
  // window.onload = function(){
  //   videoRef.current.play();
  // }
  const options = {
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout:25000,
    animateOut:'fadeOut',
    nav: false,
    dots: false,
    margin: 0,
    responsive: {
      1100: {
        items: 1,
      },
      724: {
        items: 1,
      },
      500: {
        items: 1,
      },

      370: {
        items: 1,
        innerWidth:"100%",
        outerWidth:"100%"
      },
    },
  };
  useEffect(()=>{

//  AOS.init({duration:1500})
// videoRef.current.autoplay()
  }, []);

 const resp = useGetBannerImgQuery()
  const superdata = resp.data
  if(resp.isLoading === true){return<div>{resp.isError === true ? <div><h1>something went wrong try again sometime later</h1>{console.log("owlcar section 1")}</div> : <div></div>}</div>}else{
  return (

    <div className="positionre">
      {/* <Carousel fade={true} className="owlwidth">
        <Carousel.Item interval={3000}>
          <img className="owlwidth" src={first} alt="First slide" />    
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img className="owlwidth" src={second} alt="Second slide" />
        </Carousel.Item>
      </Carousel> */}
       <OwlCarousel className="owl-theme" id="hideclass" {...options}>
                  {/* <div className="homefrontbanner">
                    <img src={first} alt="304" />
                    <div className="frontintitle">
                                    <h1 data-aos="fade-down">maison Francis Kuja</h1>
                                    <p data-aos="fade-down" data-aos-delay={700}>Learn more about our product philosophy, and the benefits of microbiome-friendly skincare.</p>
                                    <span data-aos="zoom-in" data-aos-delay={1500} >shop now</span>
                    </div>
                  </div> */}
                  {/* style={{backgroundImage:`url('https://admin.ayurherbals.co/upload/banner/${item.image}')`,backgroundSize:"cover"}} */}
                    { resp.isLoading ? <div>  loading is here</div> :  superdata.data[0].map((item)=>
         <div className="homefrontbanner" 
         style={{backgroundImage:`url(https://admin.ayurherbals.co/upload/banner_bg.jpg)`,backgroundSize:"cover",backgroundRepeat:"round"}}
         >
         {/* <img src={`https://admin.ayurherbals.co/upload/banner/${item.image}`} alt={item.banner_alt} /> */}
         <video width="500" height="305px" children="supervideo" muted controls loop ref={videoRef} >
          <source src={`https://admin.ayurherbals.co/upload/banner/video/${item.video}`}  />
         </video>
       </div>
        )}
                  
                </OwlCarousel>
                {/* <Carousel fade={true} className="owlwidth" id="secondowlc">
        <Carousel.Item interval={3000}>
          <img className="owlwidth" src={first} alt="First slide" />    
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img className="owlwidth" src={second} alt="Second slide" />
        </Carousel.Item>
      </Carousel>  */}
    </div>
  );
};
}
export default OwlCar;
