import React from 'react'
import { NavLink } from 'react-router-dom';
import { useGetAllOrderQuery } from '../services/apifile'
import { getToken } from '../services/LocalStorageSrv';
import { MdOutlineCancel } from "react-icons/md";
import { BsEye } from "react-icons/bs";
const Orders = () => {

  const supervalue = getToken("data").user_id;
  const userdata = useGetAllOrderQuery(supervalue);
  const superuser = userdata.data
  if (userdata.isLoading === true) {
    return <div>{userdata.isError === true ? <div><h1>something went wrong try again sometime later</h1>{console.log("userdata section 1")}</div> : <div></div>}</div>;
  } else {
  return (
    <div className='rightaccountcomp'>
      <div className="account3title">
        <h3>Orders</h3>
      </div>
      <div className="table-responsive">  
        <table style={{width:"100%"}}>
       <thead>
        <tr>
          <th width="15%">Order id</th>
          <th width="15%">Method</th>
          <th width="15%">Track No</th>
          <th width="15%">Status</th>
          <th width="15%">Date</th>
          <th width="7%">Total</th>
          <th width="5%">Actions</th>
        </tr>
       </thead>
       <tbody>
       {superuser.status == 1 ? superuser.data[0].map((item) => (
               <tr>
               {/* <td style={{display: "flex",
    flexDirection:"column",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems:"center"}}>
              <img src={`https://ayuradmin.stackerbee.com/upload/product/${item.image}`} style={{width:"100px"}} alt="showimg" />
               <p>{item.product_title}</p>
               </td> */}
               <td>{`ORD000${item.odr_id}`}</td>
               <td>{item.payment_method}</td>
               {item.order_status == 2 ?  <td><a href={`https://tracklite.in/track/${item.awb_traking_no}`} target="_blank" style={{color:"black",textDecoration:"none"}} rel="noopener noreferrer">Track Order</a></td> : <td>Processing</td>}
               <td>{item.order_status == 0 ? "Pending" : ""}{item.order_status == 1 ? "Received" : ""}{item.order_status == 2 ? "Shipped" : ""}{item.order_status == 3 ? "Cancelled" : ""}{item.order_status == 4 ? "RTO" : ""}{item.order_status == 5 ? "RTO Received" : ""}{item.order_status == 6 ? "RTO Intransit" : ""}{item.order_status == 7 ? "Delivered" : ""}{item.order_status == 8 ? "Fully Returned" : ""}{item.order_status == 9 ? "Partially Returned" : ""}</td>
               <td> <p style={{margin:"0px"}} >{item.order_date}</p><p style={{margin:"0px"}} >{item.order_time}</p> </td>
               <td className='name'>₹{`${item.gtotal}`}</td>
               <td> <button type="submit" style={{marginBottom:"3px",marginRight:"2px"}} className='clorder'>
               <NavLink to={`/vieworder/${item.odr_id}`} className="changeton" style={{color:"white"}} ><BsEye size={20} /></NavLink></button>
              {/* {item.order_status != 3 ? item.order_status != 2 ?  <button type="submit" className='clorder'><NavLink to={`/cancelorder/${item.odr_id}`} className="changeton" style={{color:"white"}} ><MdOutlineCancel size={20} /></NavLink></button> : <div></div> : <div></div>}  */}
               </td>
             </tr>
    )) : <tr><td colSpan={6}>No Orders</td></tr>}
       
       </tbody>
        </table>
      </div>
    </div>
  )
  }
}

export default Orders