import Footer from "./component/Footer";
import Header from "./component/Header";
import Lastfooter from "./component/Lastfooter";
import Menu from "./component/Menu";
import Minibolgwidth from "./Minibolgwidth";
import Scrolltotop from "./component/Scrolltotop";
import Socialmedia from "./component/Socialmedia";
import Pagebredcame from "./component/Pagebredcame";
import { useGetAllProductByCategoryQuery } from "./services/apifile";
import { couponcode } from "./services/LocalStorageSrv";
const Skincare = () => {
  couponcode('',200);
  const productsdata = useGetAllProductByCategoryQuery("skin-care");
  const superproduct = productsdata.data;
  if (productsdata.isLoading === true) {
    return <div></div>;
  } else {
    return (
      <div>
        <Socialmedia />
        <Header />
        <Scrolltotop />
        <Menu />
        <Pagebredcame title="skin-care" />
        <div className="fullwidth">
          <div className="fullmenu">
            {superproduct.status == "Products Not Found" ? <h4 style={{"textAlign":"center","width":"100%"}} >No product available to Display</h4> : superproduct.data[0].map((item) => (
              <Minibolgwidth
              key={item.product_id}
                ptitle={item.product_title}
                url={item.image1}
                sellp={item.selling_price}
                mrppr={item.mrp_price}
                prurl={item.pg_url}
                productid={item.product_id}
                length={item.length}
                breadth={item.breadth}
                height={item.height}
                weight={item.weight}
                weighttype={item.weight_type}
          stock={item.stock_qty}
              />
            ))}
          </div>
          
        </div>

        <Footer />
        <Lastfooter />
      </div>
    );
  }
};
export default Skincare;
