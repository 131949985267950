import "./App.css";
import Home from "./Home";
import {
  Routes,
  Route,
} from "react-router-dom";
import About from "./About";
import Contact from "./Contact";
import Blog from "./Blog";
import Blogfullwidth from "./Blogfullwidth";
import Faq from "./Faq";
import Error from "./Error";
import Cart from "./Cart";
import Blogdetails from "./Blogdetails";
import Checkout from "./Checkout";
import Productdtls from "./Productdtls";
import Suncare from "./sun-care";
import Haircare from "./hair-care";
import Skincare from "./skin-care";
import Bodycare from "./body-care";
import Facecare from "./face-care";
import Login from "./Login";
import Register from "./Register";
import MyAccount from "./Account";
import Account1 from "./component/Account1";
import Account2 from "./component/Account2";
import Updateprofile from "./Updateprofile";
import Accountup from "./Accountup";
import Accountpp from "./Accountpp";
import Paypage from "./Paypage";
import Laststep from "./Laststep";
import Cancelorder from "./Cancelorder";
import Changeaddress from "./Changeaddress";
import ViewOrder from "./component/ViewOrder";
import Nextstep from "./Nextstep";
import Thankyoupage from "./Thankyoupage";
import Career from "./Career";
import Orders from "./component/Orders";
import SelectAddress from "./SelectAddress";
import Selectproduct from "./Selectproduct";
import Lastpro from "./Lastpro";
import Lostpassword from "./Lostpassword";
import Otpcheck from "./Otpcheck";
import Modifypassword from "./Modifypassword";
import Canclledpage from "./Canclledpage";
import Lipcare from "./Lipcare";
import Privacypolicy from "./Privacypolicy";
import Termsconditions from "./Termsconditions";
import Shippingandreturnpolicy from "./Shippingandreturnpolicy";
import Specialkit from "./Special-kit";
import Editaddress from "./Editaddress";
import img1 from "./assets/img/favicon.png"
import img2 from "./assets/img/aa.jpg"
import { getToken } from "./services/LocalStorageSrv";

function App() {
  let person;
  const userdataed = getToken("data");
  if (getToken("data")) {
    person = userdataed.user_id;
  } else {
    person = 0;
  }
  const mt = false;
  if(mt == true){
return(
  <div className="mant">
    <div className="aa1">
  <img src={img1} alt="dfg" />
    </div>
    <div className="aa2">
  <img src={img2} alt="dfg" />
    </div>
  </div>
)
  }else{
  return (
    <div className="App">
      <Routes >
   <Route path="/" element={<Home />} />
      <Route path="about-us" element={<About />} />
      <Route path="privacy-policy" element={<Privacypolicy />} />
      <Route path="terms-conditions" element={<Termsconditions />} />
      <Route path="shipping-and-return-policy" element={<Shippingandreturnpolicy />} />
      <Route path="contact-us" element={<Contact />} />
      <Route path="blog" element={<Blog />} />
      <Route path="shopfullwidth" element={<Blogfullwidth />} />
      <Route path="faq" element={<Faq />} />
      <Route path="cart" element={<Cart />} />
      <Route path="blog-details/:name" element={<Blogdetails />} />
      <Route path="checkout" element={person == 0 ? <Error /> : <Checkout />} />
      <Route path="product-details/:name" element={<Productdtls />} />
      <Route path="sun-care" element={<Suncare />} />
      <Route path="nextstep" element={<Nextstep />} />
      <Route path="hair-care" element={<Haircare />} />
      <Route path="skin-care" element={<Skincare />} />
      <Route path="body-care" element={<Bodycare />} />
      <Route path="face-care" element={<Facecare />} />
      <Route path="lip-care" element={<Lipcare />} />
      <Route path="kitsandpouches" element={<Specialkit />} />
      <Route path="thankyoupage" element={<Thankyoupage />} />
      <Route path="Cancelledpage" element={<Canclledpage />} />
      <Route path="login" element={<Login />} />
      <Route path="lostpassword" element={<Lostpassword />} />
      <Route path="otpcheck" element={<Otpcheck />} />
      <Route path="modifypassword" element={<Modifypassword />} />
      <Route path="register" element={<Register />} />
      <Route path="paypage" element={<Paypage />} />
      <Route path="laststep" element={<Laststep />} />
      <Route path="cancelorder/:id" element={<Cancelorder />} />
      <Route path="selectaddress/:id" element={<SelectAddress />} />
      <Route path="selectproduct/:id/:pid" element={<Selectproduct />} />
      <Route path="lastpro/:id/:pid" element={<Lastpro />} />
      <Route path="career" element={<Career />} />
      <Route path="changeaddress/:id" element={<Changeaddress />} />
      <Route path="editaddress/:id" element={<Editaddress />} />
      <Route path="Updateprofile" element={<Updateprofile />} />
      <Route path="MyAccount" element={<MyAccount />} >
        <Route index element={<Account1 />} />
        <Route path="address" element={<Account2 />} />
        <Route path="orders" element={<Orders />} />
        <Route path="accountdetails" element={<Accountup />} />
        <Route path="accountpp" element={<Accountpp />} />
      </Route>
        <Route path="vieworder/:id" element={<ViewOrder />} />
      <Route path="*" element={<Error />} />
    </Routes>
    </div>
  )
}
}

export default App;
