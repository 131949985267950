import React from "react";
import img20 from "../assets/img/bg/leaf.gif"
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Minifeatired from "./Minifeatired";
import { useGetAllProductStatusQuery } from "../services/apifile";

const Latestblog = () => {
  // const options = {
  //   items:4,
  //   loop: true,
  //   autoplay: false, nav: true,
  //   dots:false,
  //   margin: 0,
  //   responsive:{
  //     1100:{
  //       items:4
  //     },
  //     724:{
  //       items:3
  //     },
  //     500:{
  //       items:2
  //     },

  //     310:{
  //       items:1,
  //       nav: false,
  //     }
  //   }
  // }
  const resp = useGetAllProductStatusQuery("top-seller")
  const superdata = resp.data
  if(resp.isLoading === true){return<div></div>}else{
  return (
    <div className="marginvert">
      <div className="categoryheading">
        <h3>BEST SELLERS</h3>
        
        <div className="imgbottom">
          <img src={img20} alt="uyt" className="oror" />
        </div>
      </div>
      <div className="featuredcompsagain">
      <div className="resizebox">

        {/* <OwlCarousel className='owl-theme' {...options}> */}
          
        {resp.data.status == "Page Not Found" ? <h4 style={{"textAlign":"center","width":"100%"}} >No product available to Display</h4> : resp.isLoading ? <div>  loading is here</div> :  superdata.data[0].map((item)=>  
          <Minifeatired  title={item.product_title}
          url={item.image}
          sellp={item.selling_price}
          mrppr={item.mrp_price}
          prurl={item.pg_url} 
          productid={item.product_id}
          length={item.length}
                breadth={item.breadth}
                height={item.height}
          weight={item.weight}
                weighttype={item.weight_type}
          stock={item.stock_qty} />
        )}
        {/* </OwlCarousel> */}
        </div>
      </div>
     
    </div>
  );
};
}
export default Latestblog;
