import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Newsector = () => {
  const nvg = useNavigate()
  const [hide, sethide] = useState(false);
  const [superemail, setsuperemail] = useState();
  const [response, setresponse] = useState({});
  const [inputs, setinputs] = useState({});
  const handlechange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setinputs((values) => ({ ...values, [name]: value }));
  }; 
  const submitlogin = (e) => {
    e.preventDefault();
    
      axios("https://admin.ayurherbals.co/api/newsletter", {
        method: "POST",
        mode: "no-cors",
        data: JSON.stringify(inputs),
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
        },
      }).then((res) => setresponse(res));
      setsuperemail("")  

  };
  return (
    <div className={hide ? "hidecls" : "supernews"}>
        {
            response.status == 200 ? ( <div> { response.data.status == 1 ? alert("You have been subscribed to our newsletter") : <div></div>}{ response.data.status == 1 ? window.location.reload(false) : <div></div>}
            { response.data.status == 0 ? alert("email is not valid") : <div></div>} { response.data.status == 0 ? window.location.reload(false) : <div></div>}{ response.data.status == 4 ? alert("Please Register First with This Email") : <div></div>} { response.data.status == 4 ? nvg('/register') : <div></div>} { response.data.status == 2 ? alert("Newsletter Already Subscribed") : <div></div>}{ response.data.status == 2 ? window.location.reload(false) : <div></div>} </div> ) : <div></div>
          }
      <h1 onClick={() => sethide(true)} className="d123d">
        {" "}
        x
      </h1>
      <h3 >WE CARE FOR YOU</h3>
      <p style={{}}>
      Register and avail 5% off*
      </p>
      <form
      onSubmit={submitlogin}
        style={{ display: "flex", justifyContent: "center", width: "100%" }}
      >
        <div className="fot2para displayhere" style={{ width: "85%" }}>
          {" "}
          <input
            type="email"
            style={{ height: "40px" }}
            name="nemail"
            value={superemail}
            className="emailaddress"
            placeholder="Enter Your Email"
            onChange={(e) => {setsuperemail(e.target.value); handlechange(e)}}
            required
          />{" "}
          <p
            className="emailbtn"
            style={{ height: "40px", width: "15%", display: "flex" }}
          >
            <button type="submit" className="gogog">
              <i
                className="fa-solid fa-envelope"
                style={{ fontSize: "15px" }}
              ></i>
            </button>
          </p>
        </div>
      </form>
    </div>
  );
};

export default Newsector;
