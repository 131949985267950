import Footer from "./component/Footer";
import Header from "./component/Header";
import Lastfooter from "./component/Lastfooter";
import Menu from "./component/Menu";
import "./component/Customowlforcate.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Related from "./component/Related";
import Scrolltotop from "./component/Scrolltotop";
import Socialmedia from "./component/Socialmedia";
import Pagebredcamed from "./component/Pagebredcamed";
import ReactImageMagnify from 'react-image-magnify';
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useGetSingleproductQuery } from "./services/apifile";
import { useEffect, useState } from "react";
import { getipToken, getToken, storepidToken, getprodidToken, removeprodidToken, removecouponToken, couponcode, storevariable, getstorevarible, storecartToken, removecartToken } from "./services/LocalStorageSrv";
import axios from "axios";
import { useRef } from "react";
const Productdtls = () => {
  // let sot 
  // if(getstorevarible('storewid')){
  //   sot = getstorevarible('storewid')
  // }else{
  //   sot = 123
  // }

  const getfocus = useRef();
  const takefocus = () =>{
    getfocus.focus();
  }
  const [superheight,setheight] = useState("hi")
  const [superbreadth,setbreadth] = useState("hi")
  const [superlength,setlength] = useState("hi")
  const [productw,setproductw] = useState("hi")
  const [prodprice,setprodprice] = useState(0)
  const [typew,settypew] = useState("hi")
  const [itemdata,setitemdata] = useState({})
  const [sot,setsot] = useState(123)
  const hidewe = useRef();
  const wtypehe = useRef();
  const varwe = useRef();
  const pricenum = useRef();
  const plength = useRef();
  const pbreadth = useRef();
  const pheight = useRef();
  const showfun = (e) =>{
   const abcd = e;
  }
  const [imgvalue,setimgvalue] = useState(0)
  // if(imgvalue == 0){
  // superimg = `https://admin.ayurherbals.co/upload/product/${showsun.data[0].image}`;
  // }
  // if(imgvalue == 1){
  //   superimg = `https://admin.ayurherbals.co/upload/product/${showsun.data[1].image}`;
  //   }
  // if(imgvalue == 2){
  //   superimg = `https://admin.ayurherbals.co/upload/product/${showsun.data[2].image}`;
  //   }
  //   if(imgvalue == 3){
  //     superimg = `https://admin.ayurherbals.co/upload/product/${showsun.data[3].image}`;
  //     }
  //     if(imgvalue == 4){
  //       superimg = `https://admin.ayurherbals.co/upload/product/${showsun.data[4].image}`;
  //       }
  //       if(imgvalue == 5){
  //         superimg = `https://admin.ayurherbals.co/upload/product/${showsun.data[5].image}`;
  //         }
  //         if(imgvalue == 6){
  //           superimg = `https://admin.ayurherbals.co/upload/product/${showsun.data[0].image}`;
            // }


  const [qut, setqut] = useState(1)
  const [selectw,setselectw] = useState()
  const {name} = useParams()
  const addnumber = () =>{
    if(qut >= 9 ){
    
    }else{
    setqut(parseInt(qut) + 1)
  }
}
  const subnumber = () =>{
    if(qut >= 2  ){
      setqut(parseInt(qut) - 1)
    }else{
    }
  }
  useEffect(() => {
    storevariable(1,20);
  }, []);
const resp = useGetSingleproductQuery(name)
const showsun = resp.data
const showvar = resp.variants
let idvalue
if(resp.isLoading === false){
let idvalue = showsun.data[0].product_id
}
const dosomething = (e) =>{
 if(e == 222){
  window.location.reload(false)
 }
}
const getuserip = getipToken('userip')
  const [cartvalue,setcartvalue] = useState({})
  const submitlogin = (e) => {
    let person 
    const userdataed = getToken('data')
    if(getToken('data')){
      person = userdataed.user_id 
    }else{
      person = 0
    }
    const prodido = getprodidToken('prodid') 
    let superwe
    if(productw != "hi"){
      superwe = productw
    }else{
      superwe = hidewe.current.value
    }
    let superprice
    if(prodprice != 0){
      superprice = prodprice
    }else{
      superprice = pricenum.current.value
    }
    let typeweight
    if(typew != "hi"){
      typeweight = typew
    }else{
      typeweight = wtypehe.current.value
    }

    let sph
    if(superheight != "hi"){
      sph = superheight
    }else{
      sph = pheight.current.value
    }

    let spb
    if(superbreadth != "hi"){
      spb = superbreadth
    }else{
      spb = pbreadth.current.value
    }

    let spl
    if(superlength != "hi"){
      spl = superlength
    }else{
      spl = plength.current.value
    }

    const infodata = {
      userip: getuserip.IPv4,
      productid: prodido,
      quantity: qut,
      userid: person,
      weight:superwe,
      weighttype:typeweight,
      proprice:superprice,
      height:sph,
      breadth:spb,
      length:spl,
    }
    e.preventDefault();
      axios("https://admin.ayurherbals.co/api/add-to-cart", {
        method: "POST",
        mode: "no-cors",
        data: JSON.stringify(infodata),
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
        },
      }).then((res) => setcartvalue(res));
  };

if(resp.isLoading === true){return<div></div>}else{
  return (
    <div>
      <Socialmedia />
      <Helmet>
        <title>{showsun.data[0].pg_meta_title}</title>
        <meta name='description' content={showsun.data[0].pg_meta_desc} />
        <meta name='keywords' content={showsun.data[0].pg_meta_keyword} />
        
       </Helmet>
      <Header />
      <Scrolltotop />
      <Menu />
      {cartvalue.status == 200 ? (
            <div>
              { cartvalue.data.status === 1 ? couponcode('',200) : <div></div>}
            </div>
          ) : (
            <div></div>
          )}
          {cartvalue.status == 200 ? (
            <div>
              {/* { cartvalue.data.status === 1 ? window.location.reload(false) : <div></div>} */}
            </div>
          ) : (
            <div></div>
          )}
      <Pagebredcamed title={showsun.data[0].product_title} />
      {cartvalue.status == 200 ? (
            <div>
               {cartvalue.data.status == 1 ? 
                removecartToken('cart')
              : 
                <div></div>
              }
              {cartvalue.data.status == 1 ? 
                storecartToken(cartvalue.data.data[0].incart)
              : 
                <div></div>
              }
              { cartvalue.data.status == 1 ? window.location.reload(false) : <div></div>}
            </div>
          ) : (
            <div></div>
          )}
      {resp.isLoading === false ? removeprodidToken('prodid'): <div></div> }
      {resp.isLoading === false ? storepidToken(showsun.data[0].product_id): <div></div> }
      {/* {resp.isLoading === false ? (<div>{showsun.status == "success" ? window.location.reload(false) : <div></div> }</div>): <div></div> } */}

      <div className="productbody">
        <div className="productdivide">
          <div className="productimghere">
            <div className="productlargeimg">
            <ReactImageMagnify {...{
    smallImage: {
        alt: 'Wristwatch by Ted Baker London',
        isFluidWidth: true,
        src: imgvalue === 0 ? sot != 123 ? `https://admin.ayurherbals.co/upload/product/${itemdata.image1}` : `https://admin.ayurherbals.co/upload/product/${showsun.data[0].image1}` : imgvalue == 1 ? sot != 123 ? `https://admin.ayurherbals.co/upload/product/${itemdata.image2}` : `https://admin.ayurherbals.co/upload/product/${showsun.data[0].image2}` : imgvalue == 2 ? sot != 123 ? `https://admin.ayurherbals.co/upload/product/${itemdata.image3}` : `https://admin.ayurherbals.co/upload/product/${showsun.data[0].image3}` : imgvalue == 3 ? sot != 123 ? `https://admin.ayurherbals.co/upload/product/${itemdata.image4}` : `https://admin.ayurherbals.co/upload/product/${showsun.data[0].image4}` : imgvalue == 4 ? sot != 123 ? `https://admin.ayurherbals.co/upload/product/${itemdata.image5}` : `https://admin.ayurherbals.co/upload/product/${showsun.data[0].image5}` : ``
    },
    largeImage: {
        src: imgvalue === 0 ? sot != 123 ? `https://admin.ayurherbals.co/upload/product/${itemdata.image1}` : `https://admin.ayurherbals.co/upload/product/${showsun.data[0].image1}` : imgvalue == 1 ? sot != 123 ? `https://admin.ayurherbals.co/upload/product/${itemdata.image2}` : `https://admin.ayurherbals.co/upload/product/${showsun.data[0].image2}` : imgvalue == 2 ? sot != 123 ? `https://admin.ayurherbals.co/upload/product/${itemdata.image3}` : `https://admin.ayurherbals.co/upload/product/${showsun.data[0].image3}` : imgvalue == 3 ? sot != 123 ? `https://admin.ayurherbals.co/upload/product/${itemdata.image4}` : `https://admin.ayurherbals.co/upload/product/${showsun.data[0].image4}` : imgvalue == 4 ? sot != 123 ? `https://admin.ayurherbals.co/upload/product/${itemdata.image5}` : `https://admin.ayurherbals.co/upload/product/${showsun.data[0].image5}` : ``,
        width: 1200,
        height: 1800
    }
}} />
            </div>
            <div className="extrahere">
              <div className="productowl">
                 {sot != 123 ? itemdata.image1 != null ? <div className="imgprdsection" onClick={() => setimgvalue(0)}>
                    <img src={`https://admin.ayurherbals.co/upload/product/${itemdata.image1}`} alt="304" />
                  </div> : '' : showsun.data[0].image1 != null ? <div className="imgprdsection" onClick={() => setimgvalue(0)}>
                    <img src={`https://admin.ayurherbals.co/upload/product/${showsun.data[0].image1}`} alt="304" />
                  </div> : '' }
                  {sot != 123 ? itemdata.image2 != null ? <div className="imgprdsection" onClick={() => setimgvalue(1)}>
                    <img src={`https://admin.ayurherbals.co/upload/product/${itemdata.image2}`} alt="304" />
                  </div> : "" : showsun.data[0].image2 != null ? <div className="imgprdsection" onClick={() => setimgvalue(1)}>
                    <img src={`https://admin.ayurherbals.co/upload/product/${showsun.data[0].image2}`} alt="304" />
                  </div> : ""}
                  {sot != 123 ? itemdata.image3 != null ? <div className="imgprdsection" onClick={() => setimgvalue(2)}>
                    <img src={`https://admin.ayurherbals.co/upload/product/${itemdata.image3}`} alt="304" />
                  </div> : "" : showsun.data[0].image3 != null ? <div className="imgprdsection" onClick={() => setimgvalue(2)}>
                    <img src={`https://admin.ayurherbals.co/upload/product/${showsun.data[0].image3}`} alt="304" />
                  </div> : ""}
                  {sot != 123 ? itemdata.image4 != null ? <div className="imgprdsection" onClick={() => setimgvalue(3)}>
                    <img src={`https://admin.ayurherbals.co/upload/product/${itemdata.image4}`} alt="304" />
                  </div> : "" : showsun.data[0].image4 != null ? <div className="imgprdsection" onClick={() => setimgvalue(3)}>
                    <img src={`https://admin.ayurherbals.co/upload/product/${showsun.data[0].image4}`} alt="304" />
                  </div> : ""}
                  {sot != 123 ? itemdata.image5 != null ? <div className="imgprdsection" onClick={() => setimgvalue(4)}>
                    <img src={`https://admin.ayurherbals.co/upload/product/${itemdata.image5}`} alt="304" />
                  </div> : "" : showsun.data[0].image5 != null ? <div className="imgprdsection" onClick={() => setimgvalue(4)}>
                    <img src={`https://admin.ayurherbals.co/upload/product/${showsun.data[0].image5}`} alt="304" />
                  </div> : "" }          
              </div>
            </div>
          </div>
          <div className="productdesc">
            <h1>{showsun.data[0].product_title}</h1>
            {sot == 123 ? <h4 style={{color:"red"}}>{showsun.data[0].stock_qty > 0 ? "" : "Out of Stock"}</h4> : <div>
              {showsun.variants[0] != null ? showsun.variants[0].map((item) => (
                      // <h6 className="curson" id= {productw == item.weight ? "givecolor" : ""} onClick={() =>{setsot(item.variant_id-1,400000000);setproductw(item.weight);setbreadth(item.breadth);setlength(item.length);setheight(item.height);setprodprice(item.mrp_price);settypew(item.weight_type);setitemdata(item)}} >{`${item.weight}${item.weight_type}`}</h6>
                      <div>
                        {item.variant_id-1 == sot ? <h4 style={{color:"red"}} > {item.stock_qty > 0 ? "" : "Out of Stock"}</h4> : ""}
                    </div>
                    )) : ""}
              </div>}
            <div className="price" style={{justifyContent:"START"}} >
                <div className="pricehere">
                  {sot != 123 ? <span className="pricewrite" > {`₹${itemdata.mrp_price}.00`}<span class="lessbold" style={{fontWeight:"100",fontSize:"14px"}}>(Price inclusive of all taxes)</span></span> : <span className="pricewrite"> {`₹${showsun.data[0].mrp_price}.00 `}<span class="lessbold" style={{fontWeight:"100",fontSize:"14px"}} >(Price inclusive of all taxes)</span></span>}                  
                </div>
              
              </div>
              <div className="price" style={{justifyContent:"START"}} >
                <div >
                {/* <div className="select-stylecomp"> */}
                  <input ref={hidewe} type="hidden" name="" value={showsun.data[0].weight} />
                  <input ref={pricenum} type="hidden" name="" value={showsun.data[0].mrp_price} />
                  <input ref={wtypehe} type="hidden" name="" value={showsun.data[0].weight_type} />
                  <input ref={pheight} type="hidden" name="" value={showsun.data[0].height} />
                  <input ref={pbreadth} type="hidden" name="" value={showsun.data[0].breadth} />
                  <input ref={plength} type="hidden" name="" value={showsun.data[0].length} />
                  {sot != 123 ? <input ref={varwe} type="hidden" name="" value={itemdata.weight} /> : <span></span>}
                  {/* <input type="hidden" name="" value={itemdata.weight} /> */}
                  {/* <select name="weight" onChange={(e) =>{storevariable(e.target.value,400000000);setselectw(e.target.title);dosomething(e.target.value);}} >
                    <option value={222} title="1111" >{showsun.data[0].weight}</option>
                    {showsun.variants[0] != null ? showsun.variants.map((item) => (
                      <option value={item.variant_id-1} title="34556">{item.weight}</option>
                    )) : ""}
                  </select> */}
                  <div className="superclass">
                    <h6 className="curson" id= {productw == "hi" ? "givecolor" : ""} onClick={() =>{dosomething(222)}}>{`${showsun.data[0].weight}${showsun.data[0].weight_type}`}</h6>
                    {showsun.variants[0] != null ? showsun.variants[0].map((item) => (
                      <h6 className="curson" id= {productw == item.weight ? "givecolor" : ""} onClick={() =>{setsot(item.variant_id-1,400000000);setproductw(item.weight);setbreadth(item.breadth);setlength(item.length);setheight(item.height);setprodprice(item.mrp_price);settypew(item.weight_type);setitemdata(item)}} >{`${item.weight}${item.weight_type}`}</h6>
                    )) : ""}
                  </div>
                </div>
               
              </div>
            
            <div className="addtoproduct">
            <div className="descriptionhere">
           <div dangerouslySetInnerHTML={{__html: showsun.data[0].sort_description+"...<a href='#takehere' class='superhero' ><span class='changecolors'>Read more</span></a>"}}></div>
          </div>
          <p style={{margin:"0px"}}>
              {/* {showsun.description} */}
            </p>
            <div className="editcartgo">
              
              <label htmlFor="gord">Quantity</label>
              <button onClick={subnumber} className="changenumber" ><span>-</span></button>
              <input type="number" id="gord" value={qut} max={9} min={1} readOnly onChange={(e) =>{setqut(e.target.value.replace(/[^1-9]/ig,''))}} />
              <button onClick={addnumber} className="changenumber" ><span>+</span></button>
            </div>
            {sot == 123 ?  <form onSubmit={submitlogin} >
            <div className="addtocarthered">
                <button type={showsun.data[0].stock_qty > 0 ? "submit" : "button"}>{showsun.data[0].stock_qty > 0 ? "Add To Cart" : "Out of Stock"}</button>
              </div>
              </form> :  <form onSubmit={submitlogin} >
              {showsun.variants[0] != null ? showsun.variants[0].map((item) => (
                      // <h6 className="curson" id= {productw == item.weight ? "givecolor" : ""} onClick={() =>{setsot(item.variant_id-1,400000000);setproductw(item.weight);setbreadth(item.breadth);setlength(item.length);setheight(item.height);setprodprice(item.mrp_price);settypew(item.weight_type);setitemdata(item)}} >{`${item.weight}${item.weight_type}`}</h6>
                      <div className="addtocarthered">
                        {item.variant_id-1 == sot ? <button type={item.stock_qty > 0 ? "submit" : "button"}>{item.stock_qty > 0 ? "Add To Cart" : "Out of Stock"}</button> : ""}
                    </div>
                    )) : ""}
              </form>}
           
            </div>
          </div>
          
        </div>
        <div id="takehere" className="productwhite">
          <div className="middleproduct">
            <p>DESCRIPTION</p>
          </div>
          <div className="descriptionhere">
           <div ref={getfocus} dangerouslySetInnerHTML={{__html: showsun.data[0].description}}></div>
          </div>
        </div>
      </div>
      <Related catename={showsun.category[0].pg_url} />
      <Footer />
      <Lastfooter />
    </div>
  );
};
}
export default Productdtls;
