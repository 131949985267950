import axios from "axios";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Helmet } from "react-helmet";
import { NavLink, useNavigate } from "react-router-dom";
import Footer from "./component/Footer";
import Header from "./component/Header";
import Lastfooter from "./component/Lastfooter";
import Menu from "./component/Menu";
import Pagebredcame from "./component/Pagebredcame";
import Scrolltotop from "./component/Scrolltotop";
import { storeToken } from "./services/LocalStorageSrv";

const Lostpassword = () => {
  const [response, setresponse] = useState({});
  const [inputs, setinputs] = useState({});

  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current.focus();
  }, []);
  const handlechange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setinputs((values) => ({ ...values, [name]: value }));
  }; 
  const nvg = useNavigate()
  const submitlogin = (e) => {
    e.preventDefault();
    
      axios("https://admin.ayurherbals.co/api/lostpassword", {
        method: "POST",
        mode: "no-cors",
        data: JSON.stringify(inputs),
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
        },
      }).then((res) => setresponse(res));

  };
 
  return (
    <div>
      <Header />
      <input type="text" ref={inputRef} style={{width:"0px",height:"0px",margin:"0px",padding:"0px",position:"absolute",top:"-6px",border:"none"}} />
      <Menu />
      <Pagebredcame title="login" />
      <div className="logincompnet">

        <div className="logintxtrealotp">
          <h2>Send OTP</h2>
          {
            response.status == 200 ? ( <div> { response.data.status == 1 ? alert("Otp send in your Email") : <div></div>}</div> ) : <div></div>
          }
          {
            response.status == 200 ? ( <div> { response.data.status == 1 ? nvg('/otpcheck') : <div></div>}</div> ) : <div></div>
          }
          {
            response.status == 200 ? ( <div> { response.data.status == 0 ? alert("Email id doesn't exist, Plesae register first!") : <div></div>}</div> ) : <div></div>
          }
           {
            response.status == 200 ? ( <div> { response.data.status == 0 ? window.location.reload(false) : <div></div>}</div> ) : <div></div>
          }
        </div>
        <form  onSubmit={submitlogin} >
        <div className="loginform">
          <div className="inputoneheret">
            <label htmlFor="detailone">Email *</label>
            <input type="email" name="lostemail" placeholder="Email" id="detailone" onChange={(e) => { handlechange(e)}} />
          </div>
          <div className="bottomlog">
         <div className="lostpass">
            <p><NavLink to="/login" style={{"color":"black"}}>Return Login </NavLink></p>
         </div>
         <div className="loginbtn">
            <button className="updatecartined" type="submit">Send OTP</button>
         </div>
          </div>
        </div>
        </form>
      </div>
      <Scrolltotop />
      <Footer />
      <Lastfooter />
    </div>
  );
};

export default Lostpassword;
