import React from 'react'

const Centercontent = () => {
  return (
        <div >
            <div className='superidis'>
<h4 style={{marginBottom:"1rem"}}>Join our Team</h4>
<p>As true leaders we are always happy to onboard like minded talent.<br /> If you want to be a part of our journey, then get in touch</p>
<h5  className='ddddee' >Mail Us : <a style={{border: "0px solid #e30016",backgroundColor:"#66832A",color:"#fff",padding: "5px 15px"}} href="mailto:hr@rdmcare.com">hr@rdmcare.com</a></h5>
</div>
</div>
  )
}

export default Centercontent;