import axios from 'axios'
import React, { useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { getshipadd, removecartship, storeshipadd } from '../services/LocalStorageSrv'

const Checkoutaddress = ({data,name}) => {

    const shipaddon = getshipadd();
    let chechid;
    if(getshipadd()){
      chechid = shipaddon.address_id;
    }else{
        chechid  = null;
    }
    // const [ischeck,setischeck] = useState(true)
    // const [ischeck1,setischeck1] = useState(true)
    // const [ischeck2,setischeck2] = useState(true)
    // const [ischeck3,setischeck3] = useState(true)
    // const [ischeck4,setischeck4] = useState(true)
  const nav = useNavigate();
  const storediff = (item) =>{
    console.log(item)
            console.log("first")
         storeshipadd(item);
    // if(numvalue == 1){
    //     setischeck1(!ischeck1);
    //     setischeck2(false);
    //     setischeck3(false);
    //     setischeck4(false);
    //     if(ischeck1 == true){
    //         console.log(ischeck1)
    //         console.log(item)
    //     }else{
    //         console.log(ischeck1)
    //         console.log("first")
    //     }
    // }

    // if(numvalue == 2){
    //     setischeck2(!ischeck2);
    //     setischeck1(false);
    //     setischeck3(false);
    //     setischeck4(false);
    //     if(ischeck2 == true){
    //         console.log(ischeck2)
    //         console.log(item)
    //     }else{
    //         console.log(ischeck2)
    //         console.log("first")
    //     }
    // }


    // if(numvalue == 3){
    //     setischeck3(!ischeck3);
    //     setischeck2(false);
    //     setischeck1(false);
    //     setischeck4(false);
    //     if(ischeck3 == true){
    //         console.log(ischeck3)
    //         console.log(item)
    //     }else{
    //         console.log(ischeck3)
    //         console.log("first")
    //     }
    // }

    // if(numvalue == 4){
    //     setischeck4(!ischeck4);
    //     setischeck2(false);
    //     setischeck1(false);
    //     setischeck3(false);
    //     if(ischeck4 == true){
    //         console.log(ischeck4)
    //         console.log(item)
    //     }else{
    //         console.log(ischeck4)
    //         console.log("first")
    //     }
    // }

  }

  let inm = 1
  return (
      <div className='superwrappercheck'>
      {data.status == 1 ? data.data[0].map((item) => (
      <div key={item.address_id} className={chechid == item.address_id ? "cardingcheck newborder" : "cardingcheck" }>
  <div className="card-body" style={{padding:"12px"}}>
    <div className="namefile">
    <h5 className="card-title" ref={name}>{item.name}</h5>
    {/* <input type="checkbox" className={`formcontrol`} name={`selector`} value={ischeck+inm++} onChange={(e) => {storediff(item,inm++)}}  /> */}
    </div>
    <p className="card-title">{item.phone}</p>
    <p className="card-title">{item.email}</p>
    <p className="card-text" style={{margin:"0px"}}>{`${item.address1},${item.address2},${item.city},${item.state},${item.country}-${item.zipcode}`}</p>
    <div>
    {chechid == item.address_id ? "": <button type="button" className='clorder somestyle'><NavLink className="changeton" style={{color:"white",width:"fit-content",fontSize:"14px"}} onClick={(e) => {storediff(item)}} >Add</NavLink></button> }
    {chechid == item.address_id ? <button type="button" style={{marginLeft:"6px"}} className='clorder somestyle'><NavLink className="changeton" style={{color:"white",width:"fit-content",fontSize:"14px"}} onClick={(e) => {removecartship()}} >Remove</NavLink></button> : "" }
    <button type="button" className='clorder somestyle' style={{marginLeft:"5px"}}>
               <NavLink to={`/editaddress/${item.address_id}`} className="changeton" style={{color:"white"}} ><span >Edit</span></NavLink></button>
    </div>
  </div>
</div>
    )) : <div></div>}
      </div>
  )
  }

export default Checkoutaddress