import axios from 'axios'
import React, { Suspense, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Footer from './component/Footer'
import Header from './component/Header'
import Lastfooter from './component/Lastfooter'
import Menu from './component/Menu'
import Pagebredcame from './component/Pagebredcame'
import Scrolltotop from './component/Scrolltotop'
import { useGetAddressCheckQuery, useGetSingleAddQuery } from './services/apifile'
import { getToken } from './services/LocalStorageSrv'

const Editaddress = () => {
  const nav = useNavigate();
  const {id} = useParams();
  const respte = useGetSingleAddQuery(id);

 const superres = respte.data;
  const [response,setresponse] = useState();
  const [responseA,setresponseA] = useState();
  const [responseB,setresponseB] = useState();
  const [responseC,setresponseC] = useState();
  const [responseD,setresponseD] = useState();
  const [responseE,setresponseE] = useState();
  const [responseF,setresponseF] = useState();
  const [responseG,setresponseG] = useState();
  const [responseH,setresponseH] = useState();



  const refname = useRef("");
  const refnametwo = useRef("");
  const refcity = useRef("");
  const refstate = useRef("");
  const refcountry = useRef("");
  const refzipcode = useRef("");
  const refnumber = useRef("");
  const refemail = useRef("");
  const refrealname = useRef("");
  // console.log("WSEDESWS",responseE)
  


    const submitlogin = (e) =>{
      e.preventDefault();
      let supername;
  if(responseE != undefined){
  supername = responseE;
  }else{
    supername = refname.current.defaultValue;
  }
  let supernametwo;
  if(responseD != undefined){
  supernametwo = responseD;
  }else{
    supernametwo = refnametwo.current.defaultValue;
  }
  let superrealname;
  if(responseH != undefined){
  superrealname = responseH;
  }else{
    superrealname = refrealname.current.defaultValue;
  }
  let supercity;
  if(responseA != undefined){
  supercity = responseA;
  }else{
    supercity = refcity.current.defaultValue;
  }
  let superstate;
  if(response != undefined){
  superstate = response;
  }else{
    superstate = refstate.current.defaultValue;
  }
  let supercountry;
  if(responseB != undefined){
  supercountry = responseB;
  }else{
    supercountry = refcountry.current.defaultValue;
  }
  let superzipcode;
  if(responseC != undefined){
  superzipcode = responseC;
  }else{
    superzipcode = refzipcode.current.defaultValue;
  }
  let superpphone;
  if(responseF != undefined){
    superpphone = responseF;
  }else{
    superpphone = refnumber.current.defaultValue;
  }

  let superemail;
  if(responseG != undefined){
    superemail = responseG;
  }else{
    superemail = refemail.current.defaultValue;
  }
       const collectdata = {
        id:id,
      address1:supername,
      address2:supernametwo,
      city:supercity,
      zipcode:superzipcode,
      state:superstate,
      country:supercountry,
      phone:superpphone,
      email:superemail,
      name:superrealname
      }
      if(supername == "",supernametwo == "",supercity == "",superzipcode == "",superstate == "",supercountry == ""){
        nav('/Myaccount/account2');
      }else{
      axios("https://admin.ayurherbals.co/api/updatefulladdress", {
          method: "POST",
          mode: "no-cors",
          data: JSON.stringify(collectdata),
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        }).then((res) => {
          nav('/checkout');
          window.location.reload(false)
        });
      }
      }
      if (respte.isLoading === true) {
        return <div></div>;
      } else {
  return (
    <div>
        <Header />
      <Menu />
      <div className="logincompnetup">
        <div className="logintxtrrro">
          <h2>Change Adddress</h2>
        </div>
        <form  onSubmit={submitlogin} className="submitup" >
        <div className="loginformup">
          {/* <div className="inputoneheretqqup">
            <label htmlFor="detailone">order_id *</label>
            <input type="text" name="lemail" id="detailone"/>
          </div> */}

<           div className="inputoneheretqqup">
            <label htmlFor="detail">Full Name </label>
            <input type="text" ref={refrealname} defaultValue={superres.data[0].name} name="fullname" id="detail" value={responseH} onChange={(e) => {
                    setresponseH(e.target.value.replace(/[^a-zA-Z\ \ ]/gi, "")); 
                  }} required/>
          </div>
          <div className="inputoneheretqqup">
            <label htmlFor="detail">Address1 </label>
            <input type="text" ref={refname} defaultValue={superres.data[0].address1} name="lpassword" id="detail" value={responseE} onChange={(e) => {
                    setresponseE(e.target.value.replace(/[^a-zA-Z0-9\ \@\_\.\- ]/gi, "")); 
                  }} required/>
          </div>
          <div className="inputoneheretqqup">
            <label htmlFor="detail">Address2 </label>
            <input type="text" ref={refnametwo} defaultValue={superres.data[0].address2}   name="ltext"  id="detail" value={responseD} onChange={(e) => {
                    setresponseD(e.target.value.replace(/[^a-zA-Z0-9\ \@\_\.\- ]/gi, ""));
                  }} required/>
          </div>
          <div className="inputoneheretqqup">
            <label htmlFor="detail">City</label>
            <input type="text" ref={refcity} name="ltext" pattern={/[^a-zA-Z\ ]/gi} defaultValue={superres.data[0].city} id="detail" value={responseA} onChange={(e) => {
                    setresponseA(e.target.value.replace(/[^a-zA-Z\ ]/gi, ""));
                  }} required />
          </div>
          <div className="inputoneheretqqup">
            <label htmlFor="detail">State</label>
            <input type="text" ref={refstate} name="ltext" pattern={/[^a-zA-Z\ ]/gi} defaultValue={superres.data[0].state} id="detail" value={response} onChange={(e) => {
                    setresponse(e.target.value.replace(/[^a-zA-Z\ ]/gi, ""));
                  }} required />
          </div>
          <div className="inputoneheretqqup">
            <label htmlFor="detail">Country</label>
            <input type="text" ref={refcountry} name="ltext" pattern={/[^a-zA-Z\ ]/gi} defaultValue={superres.data[0].country} id="detail" value={responseB} onChange={(e) => {
                    setresponseB(e.target.value.replace(/[^a-zA-Z\ ]/gi, ""));
                  }} required />
          </div>
          <div className="inputoneheretqqup">
            <label htmlFor="detail">Zipcode</label>
            <input type="text" ref={refzipcode} minLength={6} maxLength={6}  name="ltext" defaultValue={superres.data[0].zipcode}  id="detail" value={responseC} onChange={(e) => {
                    setresponseC(e.target.value.replace(/[^0-9]/gi, ""));
                  }} required />
          </div>
          <div className="inputoneheretqqup">
            <label htmlFor="detail">Phone</label>
            <input type="text" ref={refnumber} minLength={10} maxLength={10}  name="phone" defaultValue={superres.data[0].phone}  id="detail" value={responseF} onChange={(e) => {
                    setresponseF(e.target.value.replace(/[^0-9]/gi, ""));
                  }} required />
          </div>
          <div className="inputoneheretqqup">
            <label htmlFor="detail">Email</label>
            <input type="text" ref={refemail}  name="email" defaultValue={superres.data[0].email}  id="detail" value={responseG} onChange={(e) => {
                    setresponseG(e.target.value.replace(/[^a-zA-Z0-9\ \@\_\.\- ]/gi, ""));
                  }} required />
          </div>
          {/* <div className="inputoneheretqqup">
            <label htmlFor="detail">Cancelation Reason </label>
            <textarea style={{border:"1px solid #e5e5e5",outline:"none",height:"90px"}} name="lpassword" id="detail"/>
          </div> */}
          <div className="bottomlog">
         <div className="loginbtn">
            <button className="updatecartined" type="submit">Change Address</button>
         </div>
          </div>
        </div>
        </form>
      </div>
      <Scrolltotop />
      <Footer />
      <Lastfooter />
    </div>
  )
        }
}

export default Editaddress