import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Lastfooter from "./Lastfooter";
import Menu from "./Menu";
import Scrolltotop from "./Scrolltotop";
import Socialmedia from "./Socialmedia";
import { Helmet } from "react-helmet";
import axios from "axios";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { getToken } from "../services/LocalStorageSrv";
import { useGetAllOrderQuery, useGetAllViewOrderQuery, useGetSingleOrderQuery } from "../services/apifile";
const ViewOrder = () => {
  const {id} = useParams();
  const supervalue = getToken("data").user_id;
  const superstatus = useGetSingleOrderQuery(id);
  const orderstatushere = superstatus.data
  console.log("sdsdsfsfsdds",orderstatushere)
  console.log(superstatus);
  const supervalues = {
    id:id,
    nameid:supervalue
  }
  const userdata = useGetAllViewOrderQuery(supervalues);
  const superuser = userdata.data
  if (userdata.isLoading === true) {
    return <div>{userdata.isError === true ? <div><h1>something went wrong try again sometime later</h1>{console.log("vieworder section 1")}</div> : <div></div>}</div>;
  } else {
    if (superstatus.isLoading === true) {
      return <div>{superstatus.isError === true ? <div><h1>something went wrong try again sometime later</h1>{console.log("vieworder section 1")}</div> : <div></div>}</div>;
    } else {
  return (
    <div>
      <Socialmedia />
      <Helmet>
        <title>Cart</title>
        <meta name="description" content="ayur Cart page" />
        <meta name="keywords" content="herbal product, body care, face care" />
      </Helmet>
      <Header hlink="/" />
      <Scrolltotop />
      <Menu />

      <div className="cartsection">
        <div className="scrolltable" style={{width:"100%"}}>
            <table>
              <thead>
                <tr>
                  <th width="10%">Image</th>
                  <th width="30%">Product</th>
                  <th width="10%">Price</th>
                  <th width="10%">weight</th>
                  <th width="10%">Status</th>
                  <th width="10%">Quantity</th>
                </tr>
                </thead>
                <tbody>
                {superuser.status == 1 ? superuser.data[0].map((item) => (
               <tr>
            <td >
              <img src={`https://admin.ayurherbals.co/upload/product/${item.image}`} className="superimghere" alt="showimg" />
               </td> 
               <td>{item.product_title}</td>
               <td className='name'>₹{`${item.price}`}</td>
               <td>{`${item.weight}${item.weight_type}`}</td>
               <td>{item.order_status}</td>
               <td>{item.quantity}</td>
             </tr>
    )) : <tr><td colSpan={6}>No Orders</td></tr>}
       
       
                </tbody>
            </table>
            
        </div>
        <div style={{display:"flex",justifyContent:"center",gap:"5px"}}>
            {orderstatushere.data[0][0].order_status == 7 ? <button type="submit" className='clorder somestyle'><NavLink to={`/selectaddress/${id}`} className="changeton" style={{color:"white",width:"fit-content",fontSize:"17px"}} >Return</NavLink></button> : ""}
            {orderstatushere.data[0][0].order_status != 3 ? orderstatushere.data[0][0].order_status != 2 ?  <button type="submit" className='clorder somestyle'><NavLink to={`/cancelorder/${id}`} className="changeton" style={{color:"white",width:"fit-content",fontSize:"17px"}} >Cancel Order</NavLink></button> : <div></div> : <div></div>}
            </div>
      </div>
      <Footer />
      <Lastfooter />
    </div>
  );
                }
              }
};
// };

export default ViewOrder;
