import axios from 'axios'
import React, { useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import Footer from './component/Footer'
import Header from './component/Header'
import Lastfooter from './component/Lastfooter'
import Menu from './component/Menu'
import Scrolltotop from './component/Scrolltotop'
import { useGetAllAddressReturnQuery, useGetAllViewOrderQuery } from './services/apifile'
import { getToken, storeproductid } from './services/LocalStorageSrv'

const Selectproduct = () => {
  const [paara, setpaara] = useState([]);

  const nav = useNavigate();
  const {id,pid} = useParams();
  const supervalue = getToken("data").user_id;
  const supervalues = {
    id:id,
    nameid:supervalue
  }
  const userdata = useGetAllViewOrderQuery(supervalues);
  const [inputs, setinputs] = useState({});
  
  const handlechange = (e) => {
    const data = paara;
    data.push(e);
    setpaara(data)
  }; 
  const laststep = () =>{
     storeproductid(paara)
  }
  const superuser = userdata.data
  if (userdata.isLoading === true) {
    return <div>{userdata.isError === true ? <div><h1>something went wrong try again sometime later</h1>{console.log("vieworder section 1")}</div> : <div></div>}</div>;
  } else {
  return (
    <div>
        <Header />
      <Menu />
      <Scrolltotop />
      <div className='superwrappered' style={{position:"relative",display:"flex",width:"100%"}}>
      {superuser.status == 1 ? superuser.data[0].map((item) => (
      <div class="cardo styleprod ">
        <img src={`https://admin.ayurherbals.co/upload/product/${item.image}`} class="img-top" alt="..."></img>
  <div class="card-bodyed">
    <h6 class="card-title" style={{width:"98%"}}>{`${item.product_title}`}</h6>
    <p class="card-title name"><span className='superclassio' >Price : </span>₹{item.price}</p>
    <p class="card-title"> Qty {`: ${item.quantity}`}</p>
    <input type="checkbox" value={item.temp_id} className="form-check-input supercheck" style={{width:"1.3em",height:"1.3em",borderRadius:"50%",position:"absolute",top:"10px",right:"12px"}} onChange={(e) => { handlechange(e.target.value)}} />
  </div>
</div>
    )) : <div>Something went wrong</div>}
    <div style={{width:"100%",position:"relative",display:"flex",alignItems:"center"}} className="ptyle">
            <button type="submit" className='clorder somestyle' disabled ><NavLink onClick={laststep} to={paara.length > 0 ? `/lastpro/${id}/${pid}` : ``} className="changeton" style={{color:"white",width:"fit-content",fontSize:"17px",}} >Proceed</NavLink></button>
            </div>
      </div>
      <Footer />
      <Lastfooter />
    </div>
  )
  }
}

export default Selectproduct