import axios from 'axios';
import React, { useRef } from 'react'
import { NavLink } from 'react-router-dom';
import { useGetUserAddressQuery } from '../services/apifile'
import { getToken } from '../services/LocalStorageSrv';
import { BsPencilSquare } from 'react-icons/bs';
import { MdOutlineCancel } from "react-icons/md";

const Account2 = () => {
  const supervalue = getToken("data").user_id;
  const resp = useGetUserAddressQuery(supervalue);
  const refsubmit = useRef("");
  const deleteaddress = (dataid) => {
    const infodata = {
      addressid:refsubmit.current.name
    }
    axios("https://admin.ayurherbals.co/api/deleteaddress", {
      method: "POST",
      mode: "no-cors",
      data: JSON.stringify(infodata),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    }).then((res) =>
    window.location.reload(false)
    )}
  const superuser = resp.data;
if (resp.isLoading === true) {
  return <div>{resp.isError === true ? <div><h1>something went wrong try again sometime later</h1>{console.log("account2 section 1")}</div> : <div></div>}</div>;
} else {
  return (
    <div className='rightaccountcomp'>
      {/* <div className="account3title">
        <h3>Address</h3>
      </div>
      <div className="table-responsive">  
        <table style={{width:"100%"}}>
       <thead>
        <tr>
          <th width="20%">Address1</th>
          <th width="20%">Address2</th>
          <th width="14%">City</th>
          <th width="14%">State</th>
          <th width="14%">Zipcode</th>
          <th width="18%">Action</th>

        </tr>
       </thead>
       <tbody>
       {superuser.status == "Success" ? superuser.data[0].map((item) => (
               <tr>
               <td>{item.address1}</td>
               <td>{item.address2}</td>
               <td>{item.city}</td>
               <td>{item.state}</td>
               <td>{item.zipcode}</td>
               <td> <button type="submit" className='clorder'>
               <NavLink to={`/changeaddress/${item.address_id}`} className="changeton" style={{color:"white"}} ><BsPencilSquare /></NavLink></button>
               <button type="button" ref={refsubmit} name={item.address_id} onClick={deleteaddress} className='clorder' style={{marginTop:"5px",marginLeft:"5px"}}><MdOutlineCancel /></button>
                </td>

             </tr> 
     )) : <tr><td colSpan={7}>No Address</td></tr> } 
       
       </tbody>
        </table>
      </div> */}




<div className='superwrapperaccount'>
      {superuser.status == "Success" ? superuser.data[0].map((item) => (
      <div class="carding">
  <div class="card-body" style={{padding:"12px"}}>
    <p class="card-text" style={{margin:"0px"}}>{`${item.address1},${item.address2},${item.city},${item.state},${item.country}-${item.zipcode}`}</p>
    <button type="submit" className='clorder'>
               <NavLink to={`/changeaddress/${item.address_id}`} className="changeton" style={{color:"white"}} ><BsPencilSquare /><span style={{paddingLeft:'3px'}}>Edit</span></NavLink></button>
               <button type="button" ref={refsubmit} name={item.address_id} onClick={deleteaddress} className='clorder' style={{marginTop:"5px",marginLeft:"5px"}}><MdOutlineCancel /><span style={{paddingLeft:'3px'}}>Delete</span></button>
  </div>
</div>
    )) : <div><h1>No Address found !</h1></div>}

      </div>



    </div>
  )
              }
}

export default Account2