const storeToken = (value)=>{
    localStorage.setItem('data',JSON.stringify(value))
}
const storecartToken = (value)=>{
  localStorage.setItem('cart',JSON.stringify(value))
}

const storeipToken = (value)=>{
  localStorage.setItem('userip',JSON.stringify(value))
}
const storeproductid = (value)=>{
  localStorage.setItem('productid',JSON.stringify(value))
}

const storeotp = (value)=>{
  localStorage.setItem('otp',JSON.stringify(value))
}

const storepidToken = (value)=>{
  localStorage.setItem('prodid',JSON.stringify(value))
}

const storebillingToken = (value)=>{
  localStorage.setItem('billingdata',JSON.stringify(value))
}

const storeshipadd = (value)=>{
  localStorage.setItem('ship',JSON.stringify(value))
}

const storekeyToken = (value)=>{
  localStorage.setItem('keys',JSON.stringify(value))
}

const storevariable = (value, expiry)=>{
  localStorage.setItem('storewid',JSON.stringify(value))
  if(expiry) {
      setTimeout(()=> {
        localStorage.removeItem('storewid')
    }, expiry)
  }
}

const updatepassword = (value, expiry)=>{
  localStorage.setItem('passid',JSON.stringify(value))
  if(expiry) {
      setTimeout(()=> {
        localStorage.removeItem('passid')
    }, expiry)
  }
}

const couponcode = (value, expiry)=>{
  localStorage.setItem('couponid',JSON.stringify(value))
  if(expiry) {
      setTimeout(()=> {
        localStorage.removeItem('couponid')
    }, expiry)
  }
}


const getToken = ()=>{
  let data = localStorage.getItem('data')
  return JSON.parse(data)
}



const getotp = ()=>{
  let data = localStorage.getItem('otp')
  return JSON.parse(data)
}

const getpassid = ()=>{
  let data = localStorage.getItem('passid')
  return JSON.parse(data)
}

const getcoupon = ()=>{
  let data = localStorage.getItem('couponid')
  return JSON.parse(data)
}

const getprodidToken = ()=>{
  let data = localStorage.getItem('prodid')
  return JSON.parse(data)
}

const getstorevarible = ()=>{
  let data = localStorage.getItem('storewid')
  return JSON.parse(data)
}

const getipToken = ()=>{
  let data = localStorage.getItem('userip')
  return JSON.parse(data)
}

const getbillingToken = ()=>{
  let data = localStorage.getItem('billingdata')
  return JSON.parse(data)
}

const getshipadd = ()=>{
  let data = localStorage.getItem('ship')
  return JSON.parse(data)
}

const getkeysToken = ()=>{
  let data = localStorage.getItem('keys')
  return JSON.parse(data)
}

const getcartToken = ()=>{
  let data = localStorage.getItem('cart')
  return JSON.parse(data)
}

const getproductid = ()=>{
  let data = localStorage.getItem('productid')
  return JSON.parse(data)
}

const removeToken = (value)=>{
localStorage.removeItem(value)
  }

  const removeprodidToken = (value)=>{
    localStorage.removeItem(value)
      }

  const removecartToken = (value)=>{
    localStorage.removeItem('cart')
      }

      const removecartship = (value)=>{
        localStorage.removeItem('ship')
          }

  const removecouponToken = (value)=>{
        localStorage.removeItem(value)
          }

  export { storeToken,storeotp,removecartship, getToken, getshipadd, getotp, storeshipadd, removeToken, storecartToken, getcartToken, removecartToken, storeipToken, getipToken, storepidToken, getprodidToken,removeprodidToken, couponcode, getcoupon,removecouponToken, storebillingToken, getbillingToken, storekeyToken, getkeysToken, storeproductid, getproductid,storevariable,getstorevarible,getpassid,updatepassword }