import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Loader from './component/Loader'
import { getbillingToken, storekeyToken } from './services/LocalStorageSrv'

const Paypage = () => {
  setTimeout(() => {
   nvg('/laststep')
  }, 2000);
  let sectoken = getbillingToken('billingdata');
  let secbill
  if (getbillingToken('billingdata')) {
    secbill = sectoken;
  } else {
    secbill = 0;
  }
  const nvg = useNavigate();
  const [responsesec, setresponsesec] = useState([]);
  const seconddata = {
    tid: secbill.txnid,
    merchant_id: secbill.merchant_id,
    order_id: secbill.odrid,
    amount: `${secbill.data.gtotal}.00`,
    currency: 'INR',
    redirect_url: 'https://ayurherbals.co/responsefull.php',
    cancel_url: 'https://ayurherbals.co/cancelledpage',
    language: 'EN',
    billing_name: secbill.data.name,
    billing_address: secbill.data.addressone,
    billing_city: secbill.data.city,
    billing_state: secbill.data.state,
    billing_zip: secbill.data.pincode,
    billing_country: secbill.data.country,
    billing_tel: secbill.data.phone,
    billing_email: secbill.data.email,
    delivery_name: '',
    delivery_address: '',
    delivery_city: '',
    delivery_state: '',
    delivery_zip: '',
    delivery_country: '',
    delivery_tel: '',
    userid: '',
  };
  const postspec = () => {
  if (getbillingToken('billingdata')) {
    axios("https://admin.ayurherbals.co/api/payment", {
      method: "POST",
      mode: "no-cors",
      data: JSON.stringify(seconddata),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    }).then((res) => {
      storekeyToken(res.data)
    });
    
  }else{
    console.log("failed")
  }
  };

  useEffect(() => {
    postspec();
  }, []);
  return (
    <div>
    {/* <Header  />
    <Menu />
    <Pagebredcame title="sun-care" />
    <form method="post" action="https://www.ccavenue.com/shopzone/cc_details.jsp">
  {/* <input type="hidden" name="encRequest" value={encryptedText} /> */}
  {/* <input type="hidden" name="access_code" value="AVYM90HB32AH30MYHA" />
  <input type="submit" value="Pay with CCavenue" />
</form> */} 
    {/* <CCAvenue
  merchantId="YOUR_MERCHANT_ID"
  orderId="YOUR_ORDER_ID"
  redirectUrl="YOUR_REDIRECT_URL"
  cancelUrl="YOUR_CANCEL_URL"
  amount="YOUR_AMOUNT"
  currency="YOUR_CURRENCY"
  language="EN"
  integrations={["security", "theme", "validation"]}
  
/> */}
<Loader />
    {/* <div>
      <h1 className='center'>Pay ment page</h1>
    </div>
    <Footer /> */}
    </div>
  )
}

export default Paypage