import React, { useEffect, useState } from "react";
import Footer from "./component/Footer";
import Header from "./component/Header";
import Menu from "./component/Menu";
import Lastfooter from "./component/Lastfooter";
import Scrolltotop from "./component/Scrolltotop";
import Socialmedia from "./component/Socialmedia";
import Pagebredcame from "./component/Pagebredcame";
import { Helmet } from "react-helmet";
import axios from "axios";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { getToken, removeToken } from "./services/LocalStorageSrv";
import { useRef } from "react";
const MyAccount = () => {
  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current.focus();
  }, []);
  const nvg = useNavigate()
  const removeauth = () =>{
    removeToken('data')
    nvg("/")
   }
   
   let person;
  const userdataed = getToken("data");
  if (getToken("data")) {
    person = userdataed.user_id;
  } else {
    person = 0;
  }
   if(person == 0){
    nvg('*');
   }else{
  return (
    <div>
      <Socialmedia />
      <input type="text" ref={inputRef} style={{width:"0px",height:"0px",margin:"0px",padding:"0px",position:"absolute",top:"-6px",border:"none"}} />
      <Helmet>
        <title>My account</title>
        <meta name="description" content="ayur Contact us page" />
        <meta name="keywords" content="herbal product, body care, face care" />
      </Helmet>
      <Header />
      <Scrolltotop />
      <Menu />
      <Pagebredcame title="my-account" />
      <div className="accounttoggle">
       <div className="lefttoggle">
        <NavLink  to="/MyAccount" style={({isActive})=>{return{backgroundColor: isActive ? "#83b53b" : ''}}} className="btnsting"><p>Dashboard</p></NavLink>
        <NavLink to="orders" style={({isActive})=>{return{backgroundColor: isActive ? "#83b53b" : ''}}} className="btnsting"><p>Orders</p></NavLink>
        {/* <NavLink to="wishlist" style={({isActive})=>{return{backgroundColor: isActive ? "#83b53b" : ''}}} className="btnsting"><p>Wishlist</p></NavLink> */}
       <NavLink to="address" style={({isActive})=>{return{backgroundColor: isActive ? "#83b53b" : ''}}} className="btnsting"><p>Addresses</p></NavLink>
        <NavLink to="accountdetails" style={({isActive})=>{return{backgroundColor: isActive ? "#83b53b" : ''}}} className="btnsting"><p>Account Details</p></NavLink>
<p className="btnsting" onClick={removeauth}>Logout</p>
       </div>
       <div className="righttoggle">
        <Outlet />
       </div>
      </div>
      <Footer />
      <Lastfooter />
    </div>
  );
   }
};

export default MyAccount;
