import React from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { Helmet } from 'react-helmet'
import Footer from './component/Footer'
import Header from './component/Header'
import Lastfooter from './component/Lastfooter'
import Menu from './component/Menu'
import Pagebredcame from './component/Pagebredcame'
import Scrolltotop from './component/Scrolltotop'
import Socialmedia from './component/Socialmedia'
import { useGetAllBradCrampQuery } from './services/apifile'

const Termsconditions = () => {
    const inputRef = useRef(null);
    useEffect(() => {
      if(resp.isLoading === true){
      }else{
      inputRef.current.focus();
      }
    }, []);
    const resp = useGetAllBradCrampQuery('terms-conditions');
    const superdata = resp.data
      if(resp.isLoading === true){return<div></div>}else{
    return (
    <div>
         <Socialmedia />
        <Header />
        <Helmet>
        <title>About</title>
        <meta name='description' content='ayur about page' />
        <meta name='keywords' content='herbal product, body care, face care' />
       </Helmet>
        <Scrolltotop />
        <Menu />
        <Pagebredcame title="terms-conditions" />
        <div style={{padding:"20px 20px"}} dangerouslySetInnerHTML={{__html: superdata.data[0].description}}></div>
        <Footer />
        <Lastfooter />
    </div>
  )
  }
}

export default Termsconditions