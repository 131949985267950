import Minicareofyou from "./Minicareofyou";
import OwlCarousel from 'react-owl-carousel';
import img20 from "../assets/img/bg/leaf.gif"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useGetAllBlogsQuery } from "../services/apifile";
import Singletestimonal from "./Singletestimonal";
const Testimonal = () => {
  const options = {
    items:3,
    loop: true,
    autoplay: false, nav: true,
    dots:false,
    margin: 16,
    responsive:{
      920:{
        items:3
      },
      524:{
        items:2
      },
      300:{
        items:1
      },

      310:{
        items:1
      }
    }
  }
  const blogs = useGetAllBlogsQuery()
  const superblog = blogs.data
if(blogs.isLoading === true){return<div>{blogs.isError === true ? <div><h1>something went wrong try again sometime later</h1>{console.log("Testimonal section 1")}</div> : <div></div>}</div>}else{
  return (
    <div className="marginvert backgroundweb">
      <div className="categoryheading" style={{paddingTop:"10p"}}>
        <h3>What People Say About Us</h3>
        <div className="imgbottom">
          <img src={img20} alt="uyt" className="oror" />
        </div>
      </div>
      <div className="testimonalsir" style={{padding:"0px 30px"}}>
        <OwlCarousel className='owl-theme' {...options}>
          <Singletestimonal title="- Sheena Chaudhary" para={`The company products are very good and the prices are too affordable`} />   
          <Singletestimonal title="- Pooja Joshi" para="The products are so nice. All the products are include the natural ingredients." /> 
          <Singletestimonal title="- Sonam Bhardwaj" para="I can't believe the change  in my skin. Ayur product has been very useful and beneficial to me." /> 
          <Singletestimonal title="- Deepti Ajmani" para="I love the ayur brand. There products are very effective. I highly recommend it, if you are looking for natural & ayurvedic products." /> 
      </OwlCarousel>

      </div>
    </div>
  );
};
}
export default Testimonal;
