import React from "react";
import img from "../assets/img/icon/papyel.png";
import { useGetAllBradCrampQuery } from "../services/apifile";
const Lastfooter = () => {
  const resp = useGetAllBradCrampQuery("home")
  const superdata = resp.data
  if(resp.isLoading === true){return<div></div>}else{
  return (
    <div
      className="topborder"
    >
      <div className="lasttext">
        <div className="lastpara">
          <p>{superdata.data[0].sort_description}</p>
        </div>
        <div className="lastimg">
        {superdata.data[0].sort_description ? <img src={img} alt="error" /> : <div></div>}
        </div>
      </div>
    </div>
  );
  }
};

export default Lastfooter;
