import axios from "axios";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Footer from "./component/Footer";
import Header from "./component/Header";
import Menu from "./component/Menu";
import Pagebredcame from "./component/Pagebredcame";
import { getcartToken, getotp, getpassid, getToken, removeToken, storeotp, storeToken, updatepassword } from "./services/LocalStorageSrv";


const Modifypassword = () => {
  const nvg = useNavigate();
    const userdata = getToken('data')
    useEffect(() => {
    let person = getotp();
  if (person == 1) {
    person = 12345;
  } else {
    nvg('*');
  }
  }, []);
    const [uid,setuid] = useState(0)
    const [password,setpassword] = useState()
    const [conpass,setconpass] = useState()
    const [response,setresponse] = useState({})
    const superid = getpassid('passid')
  
  
    const submitform = (e) => {
      e.preventDefault();
      const collectdata = {
        userid:superid,
        updatedpassword:password
      }
      if(password == conpass){
      axios("https://admin.ayurherbals.co/api/user-changepassword", {
          method: "POST",
          mode: "no-cors",
          data: JSON.stringify(collectdata),
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        }).then((res) => {
          setresponse(res);
        });
        setconpass('')
        setpassword('')
    }else{
      alert("password did not match")
    }
  }

  return (
    <div>
        <Header />
        <Menu />
      <Pagebredcame title="login" />
    <div className="logincompnet">
        <div className="logintxto" style={{left:"-11px"}}>
          <h2>Update Your Password</h2>
          {response.status == 200 ? (
            <div>
               { response.data.status == 1 ? alert("Successfully Updated") : <div></div>}
               { response.data.status == 1 ? storeotp(0) : <div></div>}
               { response.data.status == 1 ? nvg('/login') : <div></div>}
               { response.data.status == 1 ? updatepassword(23,200) : <div></div>}
                           { response.data.status === 1 ? window.location.reload(false) : <div></div>}
              {response.data.status == 0 ? 
                <div>{alert("something went wrong try again")}</div>
              : 
                <div></div>
              }
               { response.data.status === 0 ? window.location.reload(false) : <div></div>}
            </div>
          ) : (
            <div></div>
          )} 
          
        </div>
        <form onSubmit={submitform}>
       
          <div className="loginform">
            
            <div className="group" style={{flexDirection:"column"}}>
                <input
                  type="hidden"
                  value={superid}
                  onChange={(e) => {setuid(e.target.value)}}
                />
              <div className="inputheret">
                <label htmlFor="detailoneq">Password *</label>
                <input
                  type="phone"
                  name="uphone"
                  id="detailoneq"
                  placeholder="Enter Your password"
                  value={password}
                  onChange={(e) => {setpassword(e.target.value)}}
                />
              </div>
              
              <div className="inputheret">
                <label htmlFor="detailoneqt">Confirm Password *</label>
                <input
                  type="phone"
                  name="uphone"
                  id="detailoneqt"
                  placeholder="Enter Your password"
                  value={conpass}
                  onChange={(e) => {setconpass(e.target.value)}}
                />
              </div>
            </div>

            
            <div className="bottomlog">
              
              <div className="loginbtn">
                <button className="updatecartined">Update Your Password</button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Footer />
  </div>
  )
          }


export default Modifypassword