import axios from 'axios'
import React from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Footer from './component/Footer'
import Header from './component/Header'
import Loader from './component/Loader'
import Menu from './component/Menu'
import Pagebredcame from './component/Pagebredcame'
import { getbillingToken, getkeysToken, removecartToken, removeToken, storekeyToken } from './services/LocalStorageSrv'

const Laststep = () => {
    const subform = useRef("");
    removecartToken("cart");
    removeToken('billingdata');
    window.onload = function(){
        subform.current.click();
      }
      useEffect(()=>{
        subform.current.submit();
      },[])
//     let personinfo;
//   const userdataedinfo = getkeysToken('keys');
//   if (getkeysToken('keys')) {
//     personinfo = 1;
//   } else {
//     personinfo = 0;
//   }


//   if(personinfo == 1){

//     const longdatae = {
//       encRequest:'a639d03fc498b0d4078d517f12fb443a66b2da5a33fc0f686b2fc5f0d0543a2bfc826f69acd0c231617394c70258f8943f73dc1d48e97227e844297b0ba80f11a35be1abb6fc6262bbfab8c6d8d4829fe34c4e7d8fd8e9bedebfb3e55f64e7b15adaeba08b2dbee997dd794c18ee282e2baec34e08f5fcf2012fbdf0355124c9338116a9a545461771519ccc5030a74e37a01aa29d4200ee52043e0e36bc1ecb85f5dafd80339e11123ae7ebdf5ffde3dadf289338cbc57283e6e7b7a78b9a7e1030889f7153dc098d6a33fd90b51030a2e74fb3fa0639b6cf8a81d890ad33e333fb386bbf9254d1bba739f90ee20eb6003984b7c20dede57f5438b0672568c536dda0e0f461ba170f53bb8838897131fa056b00e4f180c656efabfbd1d48c96831cee4b881fe97eadc9abddf700c33f1d1f04b5b56bda484f8bf214a0ace9476fa143a16127ad9d7500db4bbd2b538ce4e089134d3dcb0b6084748657600d2d0843f9e834ed4778c5a1caa666387f6e3ab1f9c9dd924c392aa0239e0c3b00cc5c07b79648ca0a74553f4f5e709e37082892aaec234598680fdd927261c433220c08451636ba87988372e3982de5b2792abbd85268da3c222297d79051b1057bc94a9f4e417244654fac4edc434d8c06283fe3ab28cdb51a8292641962cc310d27b3e17b4d9ad0778b46cbc17037c49e9f8fc342e83ad3f1b7a6037016df7aec',
//       access_code:'BC01738A6524BF03442FCABDA4EC3A52',
//      }

//     axios("https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction", {
//       method: "POST",
//       mode: "no-cors",
//       data: JSON.stringify(longdatae),
//       headers: {
//         "Content-Type": "application/json; charset=UTF-8",
//         "Access-Control-Allow-Origin": "*",
//         Accept: "application/json",
//       },
//     }).then((res) => {
//       storebillingToken(res.data)
//     });
//   }
let sectoken = getkeysToken('keys');
let secbill
if (getkeysToken('keys')) {
  secbill = sectoken;
} else {
  secbill = 0;
}
const [name,setname] = useState(secbill.encRequest)
const [second,setsecond] = useState(secbill.access_code)
const [thired,setthired] = useState(secbill.url)

  

//   useEffect(() => {
  // 4526f8a4e0bcfe5d39f8f687f6bbe8d9127b61d475de57da9336bdcafc329c141a5ba09499fc088d378067a1a5b1a1a1d38a0d529c30695653de0efc7966bc271ed1565f49f9e113df0276be593bf4cdc9f9d73050459deece330df26f6a8f2ff520076f6ac250dda63ad5c6bf82c48770c2db09a61f4a963fad199a9e1934af0030d754b2f4ebfe3669ed7b18886e713494884b3e039c9ce71511ed77edb2752330a832a787c156d4540d230e472d8de5690230c7690d7ded8012b66223176d35f25d5839c9e03e1cd48651bf3c99fa7b21ac7c96b74922eabd3227e168e235ad06d7aa5e14ed3f3d90207f4ffe050be577153ec1b1a980aab097d8868dfb18dccfd8719a41b517696a5ff28f1f26713ea14ccd0fa1d60760ec662fc5db3560bf49f69c2a7710d0231b16357f64add407a09264e448f4f02518e9b1d020526418c8df18e4f22cf51c33c081d7f198684544a12bbc4d96bb56f101e24f3290a147a7ead5cd3da44a49320b76bf7f9c14908689f94383fd6a906872c41ad91d7e312d350e2465f9047c0710770a021dcc4bbd262f1599e19c760a770484c1c828c615b051fb9993c5c1913bb5e8e21f25c503bc5c35b152e90ab4214f2557956e0550010cb1bd7a10bc98026caa78a24e750b5e522777a756f31f0fc8b443b8e9bd8e28d8aa01699545ebf83cac5d42fed536ffbee73227712586ec94e963b8adj
  // 4526f8a4e0bcfe5d39f8f687f6bbe8d9127b61d475de57da9336bdcafc329c141a5ba09499fc088d378067a1a5b1a1a1d38a0d529c30695653de0efc7966bc271ed1565f49f9e113df0276be593bf4cdc9f9d73050459deece330df26f6a8f2ff520076f6ac250dda63ad5c6bf82c48770c2db09a61f4a963fad199a9e1934af0030d754b2f4ebfe3669ed7b18886e713494884b3e039c9ce71511ed77edb2752330a832a787c156d4540d230e472d8de5690230c7690d7ded8012b66223176d35f25d5839c9e03e1cd48651bf3c99fa7b21ac7c96b74922eabd3227e168e235ad06d7aa5e14ed3f3d90207f4ffe050be577153ec1b1a980aab097d8868dfb18dccfd8719a41b517696a5ff28f1f26713ea14ccd0fa1d60760ec662fc5db3560bf49f69c2a7710d0231b16357f64add407a09264e448f4f02518e9b1d020526418c8df18e4f22cf51c33c081d7f198684544a12bbc4d96bb56f101e24f3290a147a7ead5cd3da44a49320b76bf7f9c14908689f94383fd6a906872c41ad91d7e312d350e2465f9047c0710770a021dcc4bbd262f1599e19c760a770484c1c828c615b051fb9993c5c1913bb5e8e21f25c503bc5c35b152e90ab4214f2557956e0550010cb1bd7a10bc98026caa78a24e750b5e522777a756f31f0fc8b443b8e9bd8e28d8aa01699545ebf83cac5d42fed536ffbee73227712586ec94e963b8ad
//4526f8a4e0bcfe5d39f8f687f6bbe8d9127b61d475de57da9336bdcafc329c141a5ba09499fc088d378067a1a5b1a1a1d38a0d529c30695653de0efc7966bc271ed1565f49f9e113df0276be593bf4cdc9f9d73050459deece330df26f6a8f2ff520076f6ac250dda63ad5c6bf82c48770c2db09a61f4a963fad199a9e1934af0030d754b2f4ebfe3669ed7b18886e713494884b3e039c9ce71511ed77edb2752330a832a787c156d4540d230e472d8de5690230c7690d7ded8012b66223176d35f25d5839c9e03e1cd48651bf3c99fa7b21ac7c96b74922eabd3227e168e235ad06d7aa5e14ed3f3d90207f4ffe050be577153ec1b1a980aab097d8868dfb18dccfd8719a41b517696a5ff28f1f26713ea14ccd0fa1d60760ec662fc5db3560bf49f69c2a7710d0231b16357f64add407a09264e448f4f02518e9b1d020526418c8df18e4f22cf51c33c081d7f198684544a12bbc4d96bb56f101e24f3290a147a7ead5cd3da44a49320b76bf7f9c14908689f94383fd6a906872c41ad91d7e312d350e2465f9047c0710770a021dcc4bbd262f1599e19c760a770484c1c828c615b051fb9993c5c1913bb5e8e21f25c503bc5c35b152e90ab4214f2557956e0550010cb1bd7a10bc98026caa78a24e750b5e522777a756f31f0fc8b443b8e9bd8e28d8aa01699545ebf83cac5d42fed536ffbee73227712586ec94e963b8ad
// 4526f8a4e0bcfe5d39f8f687f6bbe8d962dfa1b78a272674a7609a69f06b01f71ba5515233ddc325a6f37264dc81cb468244c775e48d86df6f633ee33cfcb5645b91eecbbb1eed2bfd8ea864957ba81a761c1aec1bdae465448fc3a6e2c7f6cf7c06af990de7288c826952f37dec1e71827e0a095791a4a70028c5de9bcc9be84ecd8581ad1726f655965653db0ae1acbf8b69040c490b20690f3c56b95b6a24b0fed16d815f73c4cc66126d2a03f55969e7e8618688f90f92f902b280f4165a0cf205cb53bbec7b0c0e24adf8653e7dc45bf1ba106a782fbcd4a13a9db09a945182e2f48a89c719585ef550d4c01bd9a4dc729671ba79dc438da72022d68d9ba6349ee6af8cad70cfee8153a4d2146987e20e82eaadbbf66c0e005d1ac5d7bc8b71c5549619f23ddfba07fad567b5525659797410371642726830dd8ce2809ef173c73ec22ebfc18222b56066cf0d8d308817f795ecb13a948647a79bf6bb61eca42b470094f47b2c0d7467a732ba234255d15011f7bff98e20d2bc8059116b220937118ba1f86a828d868155bada94518cc9ef3b030e5a656243d618395d46329a2482a1a85e004773abd356a5890a0cd83d523b62efca2e2c8f9dbd252603454b04816fbbb9fe00cf88cd153f94ff746ed4ac41727ea4538313a2001fb04bf812a20ede8945954c08444789f43fa6fa0b4c55293ccb00acf1d3a9ecf0cd08
//  postspec();
//   }, []);
  return (
    <div >
    {/* <CCAvenue
  merchantId="YOUR_MERCHANT_ID"
  orderId="YOUR_ORDER_ID"
  redirectUrl="YOUR_REDIRECT_URL"
  cancelUrl="YOUR_CANCEL_URL"
  amount="YOUR_AMOUNT"
  currency="YOUR_CURRENCY"
  language="EN"
  integrations={["security", "theme", "validation"]}
/> */}
<div className="superdiv">
  <Loader />
</div>
    <div>
      <form action={thired} style={{display:"none"}} method="post" ref={subform} >
        <input type="text" style={{height:"0px"}} name="encRequest" value={name} onChange={e =>setname(e.target.value)}  />
        <input type="text" style={{height:"0px"}} name="access_code" value={second} onChange={e =>setsecond(e.target.value)}  />
        <input type="submit" style={{height:"0px"}} ref={subform} value="submit" />
      </form>
    </div>
    </div>
  )
}

export default Laststep