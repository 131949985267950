import React from 'react'
import { NavLink } from 'react-router-dom'
import Footer from './component/Footer'
import Header from './component/Header'
import Lastfooter from './component/Lastfooter'
import Menu from './component/Menu'
import Pagebredcame from './component/Pagebredcame'
const Canclledpage = () => {
  return (
    <div>
          <Header />
        <Menu />
      <Pagebredcame title="	cancelled-page" />

        <div>
       <h1 className='thankyousty'>Something went wrong try Again </h1>
        </div>
        <div class="errorbtn"><NavLink to="/"><button>BACK TO HOME PAGE</button></NavLink></div>
        {/* <Refund /> */}
        <Footer />
        <Lastfooter />
    </div>
  )
}

export default Canclledpage