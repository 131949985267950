import React from 'react'
import { NavLink } from 'react-router-dom'
import Footer from './component/Footer'
import Header from './component/Header'
import Lastfooter from './component/Lastfooter'
import Menu from './component/Menu'
import Pagebredcame from './component/Pagebredcame'
import { removecartToken } from './services/LocalStorageSrv'

const Thankyoupage = () => {
  removecartToken("cart");
  return (
    <div>
        <Header />
        <Menu />
      <Pagebredcame title="thank-you-page" />
          
        <div>
        {/* <form action="https://api.ccavenue.com/refund" method="post"> */}
        {/* https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction */}
        {/* <form action="https://test.ccavenue.com/transaction/transaction.do?command=initiateRefundTransaction" method="get"> */}
  {/* <input type="text" name="enc_request"/> */}
        {/* <input type="text" style={{height:"0px"}} name="access_code" value="AVMI04JL51AS57IMSA"  /> */}
  {/* <input type="submit" value="Refund"/> */}
{/* </form> */}

       <h1 className='thankyousty'>Thank you for shopping with us. We will be shipping your order to you soon.</h1>
        </div>
        <div class="errorbtn"><NavLink to="/"><button>BACK TO HOME PAGE</button></NavLink></div>
        <Footer />
        <Lastfooter />
    </div>
  )
}

export default Thankyoupage