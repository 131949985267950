import React, { useEffect } from 'react'
import Footer from './component/Footer'
import Header from './component/Header'
import Lastfooter from './component/Lastfooter'
import Menu from './component/Menu'
import Pagebredcame from './component/Pagebredcame'
import Scrolltotop from './component/Scrolltotop'
import Socialmedia from './component/Socialmedia'
import { Helmet } from 'react-helmet'
import { useRef } from 'react'
import { useGetAllBradCrampQuery } from './services/apifile'
const About = () => {
  const inputRef = useRef(null);
  useEffect(() => {
    if(resp.isLoading === true){
    }else{
    inputRef.current.focus();
    }
  }, []);
  const resp = useGetAllBradCrampQuery('about-us');
  const superdata = resp.data
    if(resp.isLoading === true){return<div></div>}else{
  return (
    <div>
            <input type="text" ref={inputRef} style={{width:"0px",height:"0px",margin:"0px",padding:"0px",position:"absolute",top:"-6px",border:"none"}} />
      <Socialmedia />
        <Header />
        <Helmet>
        <title>About</title>
        <meta name='description' content='ayur about page' />
        <meta name='keywords' content='herbal product, body care, face care' />
       </Helmet>
        <Scrolltotop />
        <Menu />
        <Pagebredcame title="about-us" />
        <div style={{padding:"20px 20px"}} dangerouslySetInnerHTML={{__html: superdata.data[0].description}}></div>
        <Footer />
        <Lastfooter />
    </div>
  )
    }
}

export default About