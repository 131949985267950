import React from 'react'
import img20 from "../assets/img/bg/leaf.gif"

const Singletestimonal = ({title,para}) => {
  return (
    <div>
     <div className="testmi">
        <div style={{display:"flex",justifyContent:"center",padding:"27px 0px"}} >
     <img src={img20} alt="uyt" className="oror" style={{width:"100px"}} />
        </div>
     <p>{para}</p>
     <h4>{title}</h4>
     </div>
    </div>
  )
}

export default Singletestimonal