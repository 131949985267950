import React, { useEffect, useState } from "react";
import Footer from "./component/Footer";
import Header from "./component/Header";
import Lastfooter from "./component/Lastfooter";
import Menu from "./component/Menu";
import Scrolltotop from "./component/Scrolltotop";
import Socialmedia from "./component/Socialmedia";
import { Helmet } from "react-helmet";
import {
  couponcode,
  getcoupon,
  getipToken,
  getToken,
  removecartship,
  removecartToken,
  removecouponToken,
  storecartToken,
} from "./services/LocalStorageSrv";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
const Cart = () => {
  // const incart = getcartToken('cart')
  // console.log("dkkdkd",incart)
  const nvg = useNavigate()
  const [coupon, setcoupon] = useState();
  const [checkded, setcheckded] = useState({});
  let person;
  const userdataed = getToken("data");
  if (getToken("data")) {
    person = userdataed.user_id;
  } else {
    person = 0;
  }
  let couponc = getcoupon('couponid');
  let percoupon
  if (getcoupon('couponid')) {
    percoupon = couponc;
  } else {
    percoupon = 0;
  }
  const romovehere = () =>{
    couponcode('',200)
   window.location.reload(false)
  }
  const userpinfo = getipToken();

  const thisdata = {
    userip: userpinfo.IPv4,
    userid: person,
  };
  const [response, setresponse] = useState([]);
  function redlogin(){
    couponcode('',200);
    alert("please login first");
    nvg('/login')
  }
  const postspec = () => {
    axios("https://admin.ayurherbals.co/api/viewcartpost", {
      method: "POST",
      mode: "no-cors",
      data: JSON.stringify(thisdata),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    }).then((res) => {
      setresponse(res);
    });
  };

  useEffect(() => {
    removecartship();
    postspec();
  }, []);

  function Sandid(id) {
    const delid = {
      tempid: id,
    };
    axios("https://admin.ayurherbals.co/api/deletecart", {
      method: "POST",
      mode: "no-cors",
      data: JSON.stringify(delid),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    }).then((res) => {
      setcheckded(res);
    });
  }
  // const [subtotalafter ,setsubtotalafter] = useState
  const [couponval, setcouponval] = useState({});
  const Sandcoupon = () => {
    if(response.status == 200){
      if(response.data.status == 1){
        const cdata = {
          coupon: coupon,
          subtotal: response.data.subtotal,
          userid:person,
        };
        axios("https://admin.ayurherbals.co/api/apply-coupon", {
          method: "POST",
          mode: "no-cors",
          data: JSON.stringify(cdata),
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        }).then((res) => {
          setcouponval(res);
        });
      }
    }  
  };

  return (
    <div>
      <Socialmedia />
      <Helmet>
        <title>Cart</title>
        <meta name="description" content="ayur Cart page" />
        <meta name="keywords" content="herbal product, body care, face care" />
      </Helmet>
      <Header hlink="/" />
      <Scrolltotop />
      <Menu />
      {/* <Pagebredcame title="CART PAGE" /> */}

      <div className="cartsection">
        <div></div>
        <div className="scrolltable">
          {checkded.status == 200 ? (
            <div>
              {checkded.data.status == 1 ? (
                couponcode('',200)
              ) : (
                couponcode('',200)
              )}
              {checkded.data.status == 1 ? (
                removecartToken('cart')
              ) : (
                <div></div>
              )}
              {checkded.data.status == 1 ? (
                removecouponToken("couponid")
              ) : (
                <div></div>
              )}
              {checkded.data.status == 1 ? (
                window.location.reload(false)
              ) : (
                <div></div>
              )}
            </div>
          ) : (
            <div></div>
          )}
          {response.status == 200 ? (
                <div>
                  {response.data.status == 1 ? response.data.incart > 0 ? storecartToken(response.data.incart) :  storecartToken(0) : ( <div></div> )}
                  {response.data.status == 0 ? removecartToken('cart') : ( <div></div> )}
                </div>
              ) : (
                <div></div>
              )}
          {response.status == 200 ? (
            <table>
              <thead>
                <tr>
                  <th>Delete</th>
                  <th>Image</th>
                  <th>Product</th>
                  <th>Weight</th>
                  <th>Price</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              {response.status == 200 ? (
                <tbody>
                  {" "}
                  {response.data.status == 1 ? (
                    response.data.data.map((item) => (
                      <tr>
                        <td className="hoveref">
                          <button
                            style={{ border: "none" }}
                            onClick={() => Sandid(item.temp_id)}
                          >
                            <i className="fa-solid fa-trash-can"></i>
                          </button>
                        </td>
                        <td className="cartreimg">
                          <img
                            src={`https://admin.ayurherbals.co/upload/product/${item.image}`}
                            alt="eeid" className="superimghere"
                          />
                        </td>
                        <td>{item.product_title}</td>
                        <td className="dafo">{`${item.weight}${item.weight_type}`}</td>
                        <td className="dafo">{`₹${item.price}.00`}</td>
                        <td>
                          <div className="editcart">
                          <p>{item.quantity}</p>
                            {/* <input
                              type="text"
                              name=""
                              id=""
                              value={item.quantity}
                            /> */}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6}>
                        <p style={{ margin: "0px", padding: "10px 0px" }}>
                          No Items In Your Cart
                        </p>
                      </td>
                    </tr>
                  )}
                </tbody>
              ) : (
                <div></div>
              )}
            </table>
          ) : (
            <div></div>
          )}
        </div>
        {response.status == 200 ? <div>
          {response.data.status == 1 ? <div className="coupen">
          <div className="coupen1">
            <div className="coupentitle">
              <p>COUPON</p>
            </div>
            <div className="coupenpara">
              <p>Enter your coupon code if you have one.</p>
            </div>
            <div className="inorb">    
              <input
                type="text"
                name="coupon"
                value={coupon}
                placeholder="coupon code"
                onChange={(e) => setcoupon(e.target.value)}
              />
              {response.status == 200 ? (
                <div>
                  {response.data.status == 1 ? (
                    <button className="updatecart" onClick={Sandcoupon}>
                      {" "}
                      APPLY COUPON{" "}
                    </button>
                  ) : (
                    <div></div>
                  )}
                </div>
              ) : (
                <div></div>
              )}
            </div>
            {percoupon != "" ? <div className="coupener" onClick={romovehere}>
                    <p   className="coponremove"
                    style={{ fontFamily: "system-ui" }}>
                      {percoupon.code}
                    </p>
                    <p className="removebtn">x</p>
                </div> : ''}
            {couponval.status == 200 ? <div>{couponval.data.status == 1 ? <div> </div> : <div><p></p></div>}</div> : <div></div>}
            {couponval.status == 200 ? <div>{couponval.data.status == 4 ? <div className="errorcls"><p>This Coupon is Not Allowed</p> </div> : <div><p></p></div>}</div> : <div></div>}
            {couponval.status == 200 ? <div>{couponval.data.status == 3 ? <div className="errorcls"><p>Please Login First</p> </div> : <div><p></p></div>}</div> : <div></div>}
            {couponval.status == 200 ? <div>{couponval.data.status == 2 ? <div className="errorcls"><p>You already use this couponcode</p> </div> : <div><p></p></div>}</div> : <div></div>}
            {couponval.status == 200 ? <div>{couponval.data.status == 0 ? <div className="errorcls"><p>Coupon Can't be applied</p> </div> : <div><p></p></div>}</div> : <div></div>}

          </div>
          <div className="coupen2">
            <div className="coupentitle">
              <p>CART TOTALS</p>
            </div>
            <div className="coupen2padd">
              <div className="coupen2para">
                <p>Subtotal</p>
                {response.status == 200 ? (
                  <div>
                    {response.data.status == 1 ? (
                      <div>
                        <p
                        className="fofr"
                        style={{ fontFamily: "system-ui" }}
                      >{`₹${response.data.withoutshipsubtotal}.00`}</p>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
                  <div>
                    {percoupon != "" ? <div className="coupen2para">
                    <p>Coupon<span className="makeitgreen">{`(${percoupon.code})`}</span></p>
                    <p   className="fofr"
                    style={{ fontFamily: "system-ui" }}>
                      {`- ₹${percoupon.disamount}.00`}{" "}
                    </p>
                </div> : ''}
                </div>
              <div className="coupen2para">
                <p>Shipping</p>
                <p style={{ fontFamily: "system-ui" }}>
                  {/* {" "}
                  <span style={{ paddingRight: "45px" }}>Flat Rate:</span>{" "}
                  {`₹0`} */}
                  {response.status == 200 ? (
                  <div>
                    {response.data.status == 1 ? (
                      <div>
                        <p
                        className="fofr"
                        style={{ fontFamily: "system-ui" }}
                      >{`₹${response.data.shipcharge}.00`}</p>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                ) : (
                  <div></div>
                )}
                </p>
              </div>
              <div className="coupen2paralast">
                <p>Calculate shipping</p>
              </div>
              <div className="coupen2para" style={{ paddingTop: "24px" }}>
                <p>Subtotal</p>
                {response.status == 200 ? (
                  <div>
                    {response.data.status == 1 ? (

                        <div>
                          <div className="coupen2para">
                          <p   className="fofr"
                          style={{ fontFamily: "system-ui" }}>
                            {" "}
                            {couponval.status == 200 ? <div>{couponval.data.status == 1 ? <div>{couponcode(couponval.data,900000)}</div> : <div></div>}</div> : <div></div>}
                            {percoupon != 0 ? `₹${percoupon.subtotal}.00` : `₹${response.data.subtotal}.00`}
                          </p>
                      </div>


                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className="coupen2paralasted">
                {person == 0 ? <button className="updatecartined" onClick={()=>redlogin()}>Proceed To Checkout</button> : <NavLink to="/checkout">
                <button className="updatecartined">Proceed To Checkout</button>
                </NavLink>}
              </div>
            </div>
          </div>
        </div> : ''}
        </div> : <div></div>}
      </div>
      <Footer />
      <Lastfooter />
    </div>
  );
};
// };

export default Cart;
