import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { couponcode, removeToken } from '../services/LocalStorageSrv';

const Logout = () => {
    const nvg = useNavigate();
    const removeauth = () =>{
      couponcode('',200);
        removeToken('data')
       }
  return (
    <div  onClick={removeauth} >
         <a href='https://ayurherbals.co/' className="changetext"  >
                <p>Logout</p>
              </a>
    </div>
  )
}

export default Logout