import React from "react";
import img20 from "../assets/img/bg/leaf.gif"
import img21 from "../assets/img/bg/wecareofyou.png"
import { useGetAllBradCrampQuery } from "../services/apifile";
const Realcaretoyou = () => {
  const resp = useGetAllBradCrampQuery("home")
    const superdata = resp.data
    if(resp.isLoading === true){return<div>{resp.isError === true ? <div><h1>something went wrong try again sometime later</h1>{console.log("realcaretoyou section 1")}</div> : <div></div>}</div>}else{
  return (
    <div className="marginvert backgroundweb">
      <div className="categoryheading">
        <h3>WE CARE FOR YOU</h3>
        <div className="imgbottom">
          <img src={img20} alt="uyt" className="oror" />
        </div>
      </div>
      <div className=" postioncare">
        <img
          src={`https://admin.ayurherbals.co/upload/${superdata.data[0].image}`}
          alt="error"
        />
      </div>
    </div>
  );
    }
};

export default Realcaretoyou;
