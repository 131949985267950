import React, { useEffect } from 'react'
import Header from './component/Header'
import Menu from './component/Menu'
import Footer from './component/Footer'
import Lastfooter from './component/Lastfooter'
import Socialmedia from './component/Socialmedia'
import Scrolltotop from './component/Scrolltotop'
import Pagebredcame from './component/Pagebredcame'
import Centercontent from './component/Centercontent'
import img from './assets/img/ABC.jpg'
import { useRef } from 'react'
const Career = () => {
  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current.focus();
  }, []);
  return (
    <div>
        <Socialmedia />
        <Header />
        <Menu />
        <Scrolltotop />
        {/* <Pagebredcame title="sun-care" /> */}

        <div className="bannerhere">
            <div className="bannerimg">
            <input type="text" ref={inputRef} style={{width:"0px",height:"0px",margin:"0px",padding:"0px",position:"absolute",top:"-6px",border:"none"}} />
            <img src={img} alt="304" />
            </div>
            <p className='superbread' style={{backgroundColor:"#fff",margin:"0px"}}><span>Home</span> &gt; <span>Career</span></p>
        </div>
        <Centercontent />
        <Footer />
        <Lastfooter />
    </div>
  )
}

export default Career