
import axios from "axios";
import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useGetWishlistByIdQuery } from "../services/apifile";
import { getipToken, getToken, removecartToken, storecartToken } from "../services/LocalStorageSrv";
const Minifeatired = ({ url, title,prurl,mrppr,productid,stock,weight,weighttype,height,breadth,length }) => {
 const getuserip = getipToken()
  const [cartvalue,setcartvalue] = useState({})
  let person 
  const userdataed = getToken('data')
  if(getToken('data')){
    person = userdataed.user_id 
  }else{
    person = 0
  }

  const addwishlist = () => {
    const infodata = {
      pid: productid,
      userid: person,
      purl: prurl
    }
      axios("https://admin.ayurherbals.co/api/whishlist", {
        method: "POST",
        mode: "no-cors",
        data: JSON.stringify(infodata),
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
        },
      }).then((res) =>window.location.reload(false))}
      const delwishlist = () => {
        const infodata = {
          productid: productid,
          userid: person,
        }
        axios("https://admin.ayurherbals.co/api/deletewishlist", {
          method: "POST",
          mode: "no-cors",
          data: JSON.stringify(infodata),
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        }).then((res) =>window.location.reload(false))}
  const submitlogin = (e) => {
    let person 
    const userdataed = getToken('data')
    if(getToken('data')){
      person = userdataed.user_id 
    }else{
      person = 0
    }

    const infodata = {
      userip: getuserip.IPv4,
      productid: productid,
      quantity: 1,
      userid: person,
      weight:weight,
      weighttype:weighttype,
      proprice:mrppr,
      height:height,
      breadth:breadth,
      length:length,
    }
    
    e.preventDefault();
      axios("https://admin.ayurherbals.co/api/add-to-cart", {
        method: "POST",
        mode: "no-cors",
        data: JSON.stringify(infodata),
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
        },
      }).then((res) => setcartvalue(res));
  };
  const formdata = {
    person:person,
    productid:productid
  }
  const userdata = useGetWishlistByIdQuery(formdata);
  const superuser = userdata.data

    if (userdata.isLoading === true) {
      return <div>{userdata.isError === true ? <div><h1>something went wrong try again sometime later</h1>{console.log("minifeatired section 1")}</div> : <div></div>}</div>;
    } else {
  return (
    <div className="paddingttor">
    <div className="featcomp">
      <div className={stock > 0 ? "imgcomp" : "imgcompres" }>
    <NavLink to={`/product-details/${prurl}`} className="changeton">
        <img src={`https://admin.ayurherbals.co/upload/product/${url}`} alt="showimg" className="resizeimages" />
      </NavLink>
          {person != 0 ? <div className="likebtn"  >
        {superuser.status == 1 ? <i class="fa-solid fa-heart Active" onClick={delwishlist}></i> : <i  className="fa-regular fa-heart " onClick={addwishlist}></i> }
            
          </div> : <div></div>}
      </div>
      <div className="compdetails">
        <div className="comppara">
        <NavLink to={`/product-details/${prurl}`} className="changeton">
          <h4>{`${title.substr(0,24)} ...`}</h4>
          </NavLink>
        </div>
        
        <div className="price">
        <div className="pricehere">
                  
                  {"   "}
                  <span className="pricewrite"> {`₹${mrppr}.00`}</span>
                </div>
         
        </div>
        
        <div className="comppara">
        <NavLink to={`/product-details/${prurl}`} className="changeton">
          <h6 style={{textAlign:"center",color:"#727271",margin:"0px",fontSize:"11px"}}><span style={{fontSize:"12px",fontWeight:"bold"}}>MRP</span>(incl. of all taxes)</h6>
          </NavLink>
        </div>
      </div>
      
      {cartvalue.status == 200 ? (
            <div>
               {cartvalue.data.status == 1 ? 
                removecartToken('cart')
              : 
                <div></div>
              }
              {cartvalue.data.status == 1 ? 
                storecartToken(cartvalue.data.data[0].incart)
              : 
                <div></div>
              }
              { cartvalue.data.status == 1 ? window.location.reload(false) : <div></div>}
            </div>
          ) : (
            <div></div>
          )}
          
      <form onSubmit={submitlogin} >
      <div className="addtocarthere">
                <button type={stock > 0 ? "submit" : "button"} >Add To Cart</button>
              </div>
              </form>
    </div>
    </div>
  );
          }      
};

export default Minifeatired;
