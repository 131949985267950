import MiniCategory from "./MiniCategory";
import img20 from "../assets/img/bg/leaf.gif"
import { useGetAllCategoriesQuery } from "../services/apifile";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const HomeCategory = () => {
  const options = {
    items:4,
    loop: false,
    autoplay: false, nav: true,
    dots:false,
    margin: 0,
    responsive:{
      1100:{
        items:5
      },
      724:{
        items:4
      },
      500:{
        items:3
      },

      310:{
        items:3
      }
    }
  }
  const resp = useGetAllCategoriesQuery()
  const superdata = resp.data
if(resp.isLoading === true){return<div>{resp.isError === true ? <div><h1>something went wrong try again sometime later</h1>{console.log("homecategory page section 1")}</div> : <div></div>}</div>}else{
  return (
    <div className="givecolor">
      <div className="categoryheading ">
        <h3>Category</h3>
        <div className="imgbottom">
          <img src={img20} alt="uyt" className="oror" />
        </div>
      </div>
      <div className="outercate">
        <OwlCarousel className='owl-theme' {...options}>
        { resp.isLoading ? <div>  loading is here</div> :  superdata.data[0].map((item)=>
          <MiniCategory key={item.product_cat_id} uniqe={item.product_cat_id} title={item.product_category} url={item.image1} linkgo={item.pg_url} />
          )}
          </OwlCarousel>
      </div>
    </div>
  );
}
};

export default HomeCategory;
