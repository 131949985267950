import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Footer from './component/Footer'
import Header from './component/Header'
import Lastfooter from './component/Lastfooter'
import Menu from './component/Menu'
import Pagebredcame from './component/Pagebredcame'
import Scrolltotop from './component/Scrolltotop'

const Cancelorder = () => {
  // const nav = useNavigate()
  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current.focus();
  }, []);
  const [response,setresponse] = useState({})
  const [textfield,settextfield] = useState()
  const nav = useNavigate();
  const {id} = useParams();
    const submitlogin = (e) =>{
      e.preventDefault();
      const collectdata = {
        orderid:id,
        cancellation_reason:textfield
      }

      axios("https://admin.ayurherbals.co/api/cancellation", {
          method: "POST",
          mode: "no-cors",
          data: JSON.stringify(collectdata),
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        }).then((res) => {
          nav('/Myaccount/orders');
   window.location.reload(false) 
        });

    }
  return (
    <div>
        <Header />
        <input type="text" ref={inputRef} style={{width:"0px",height:"0px",margin:"0px",padding:"0px",position:"absolute",top:"-6px",border:"none"}} />
      <Menu />
      <div className="logincompnet">
        <div className="logintxtrealing">
          <h2>Cancel Order</h2>
        </div>
        <form  onSubmit={submitlogin} >
        <div className="loginform">
          {/* <div className="inputoneheretqq">
            <label htmlFor="detailone">order_id *</label>
            <input type="text" name="lemail" id="detailone"/>
          </div> */}

          {/* <div className="inputoneheretqq">
            <label htmlFor="detail">Product Name *</label>
            <input type="password" name="lpassword" id="detail"/>
          </div> */}
          <div className="inputoneheretqq">
            <label htmlFor="detail">Cancelation Reason </label>
            <textarea style={{border:"1px solid #e5e5e5",outline:"none",height:"90px"}} name="lpassword" value={textfield} id="detail" 
                  onChange={(e) => {settextfield(e.target.value)}}
                  />
          </div>
          <div className="bottomlog">
         <div className="loginbtn">
            <button className="updatecartined" type="submit">Cancel Order</button>
         </div>
          </div>
        </div>
        </form>
      </div>
      <Scrolltotop />
      <Footer />
      <Lastfooter />
    </div>
  )
}

export default Cancelorder