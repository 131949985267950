import React from 'react'
import { useNavigate } from 'react-router-dom';
import Loader from './component/Loader';

const Nextstep = () => {
    const nvg = useNavigate()
    setTimeout(() => {
        nvg('/paypage')
       }, 2000);
  return (
    <div>
        <Loader />
    </div>
  )
}

export default Nextstep