import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import img123 from "../assets/img/icon/abcd.png"
import { useGetFiveCateNameQuery,useGetSocailMediaQuery } from "../services/apifile";
import { getToken } from "../services/LocalStorageSrv";

const Menu = () => {

  const resp = useGetSocailMediaQuery();
  const superdata = resp.data;

  const [getip,setgetip] = useState({})
  const userdata = () => {
    fetch(
      "https://geolocation-db.com/json/67273a00-5c4b-11ed-9204-d161c2da74ce"
    )
      .then((response) => response.json())
      .then((data) => setgetip(data));
  };
  useEffect(() => {
    userdata();
  }, []);
  let person;
  const userdataed = getToken("data");
  if (getToken("data")) {
    person = userdataed.user_id;
  } else {
    person = 0;
  }
  let userrealip
  if (getip) {
    userrealip = getip.IPv4;
  } else {
    userrealip = "";
  }
let respofprod
const Handlesearch = (e) =>{
console.log(e)
}
  const thisdata = {
    userip: userrealip,
    userid: person,
  };
  

  const [response, setresponse] = useState([]);

  const postspec = () => {
    if(userrealip){
    try {
      axios("https://admin.ayurherbals.co/api/viewcartpost", {
      method: "POST",
      mode: "no-cors",
      data: JSON.stringify(thisdata),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    }).then((res) => {
      setresponse(res);
    });
    } catch (error) {
      console.log("menu section error 2",error);
    }
  }else{
    
  }
}


  useEffect(() => {
      postspec();
  }, [userrealip]);




const allcatename = useGetFiveCateNameQuery()
  const [hide, sethide] = useState(false);
 const [fix, setfixed] = useState(false);
 const superproduct = allcatename.data;
 const localdata = getToken('data')
 if (allcatename.isLoading === true) {
   return <div>{allcatename.isError === true ? <div><h1>something went wrong try again sometime later</h1>{console.log("menu section 3")}</div> : <div></div>}</div>;
 } else {
  if (resp.isLoading === true) {
    return <div>{resp.isError === true ? <div><h1>something went wrong try again sometime later</h1>{console.log("menu section 4")}</div> : <div></div>}</div>;
  } else {
  return (
    <div className="menuwidth">
      <ul className={fix ? 'stkiymenu ullist' : 'ullist'}>
       
      {superproduct.data[0].map((item) => (
        <li key={item.menu_id} > <NavLink to={`/${item.menu_url}`} className="removest">{item.menu_title}</NavLink> </li>
        ))}
      </ul>
      <div className="menuicon">
        <NavLink to='/cart' style={{color:"black",textDecoration:"none"}}>
        <img src={img123} alt="error" className="carticoned" />
        {response.status == 200 ? (<div>{response.data.status == 1 ? <h2 style={{color:"white"}} className="phonecls" >{response.data.incart}</h2>: <div></div>}{response.data.status == 0 ? <h2 style={{color:"white"}} className="phonecls">{response.data.incart}</h2>: <div></div>}</div>) : <div></div> }     

        </NavLink>
        <p
          style={{
            margin: "0px",
          }}
          onClick={() => sethide(!hide)}
        >
          <i className="fa-solid fa-bars"></i>
        </p>
      </div>
      {hide ? (
        <div className="slidemenu">
          <div className="closebtnhere">
            <div className="closehere" onClick={() => sethide(!hide)}>
              <i className="fa-sharp fa-solid fa-xmark"></i>
            </div>
          </div>
          <div className="supermenu">
            <ul className="dropmenu">
            
              {superproduct.data[0].map((item) => (
              <li className="tab" key={item.menu_id}>
                <p style={{ margin: "0px" }}><NavLink to={`/${item.menu_url}`} className="removest">{item.menu_title}</NavLink></p>
              </li>
                ))}
                {localdata ? <li className="tab">
                <p style={{ margin: "0px" }}><NavLink to="/Myaccount" className="removest">Dashboard</NavLink></p>
              </li> : <li className="tab">
                <p style={{ margin: "0px" }} className="removest"><NavLink to="/login" className="removest">Login/Register</NavLink></p>
              </li>}
              {/* {localdata ? <li>
              </li> : <li className="tab">
                <p style={{ margin: "0px" }}><NavLink to="/Register" className="removest">Register</NavLink></p>
              </li>} */}
                 
              <li className="menubottom">
                <div className="split">
                  <div className="here">
                    <span style={{ padding: "2px 4px 0px 0px" }}>
                      <i className="fa-regular fa-envelope"></i>
                    </span>{" "}
                    <p className="givefontz"><a className="uncolor" href={`mailto:ccare@rdmcare.com`}>ccare@rdmcare.com</a></p>
                  </div>
                  <div className="sicons">
                    <ul>
                    {superdata.data[0].facebook == 1 ? <a
            href={superdata.data[0].website_facebook}
            target="_blank"
            style={{ color: "#25330F",textDecoration:'none' }}
          >
            <li className="facebook">
              <div>
                <i className="fa-brands fa-facebook-f"></i>
              </div>
            </li>
          </a> : <div></div>}
                     
          {superdata.data[0].twitter == 1 ? <a href={superdata.data[0].website_twitter} target="_blank" style={{ color: "#25330F",textDecoration:'none' }}>
            <li className="twitter_social">
              <div>
                <i className="fa-brands fa-twitter"></i>
              </div>
            </li>
          </a> : <div></div>}
          
          {superdata.data[0].instagram == 1 ? <a
            href={superdata.data[0].website_instagram}
            target="_blank"
            style={{ color: "#25330F",textDecoration:'none',textDecoration:'none' }}
          >
            <li className="twitter_social">
              <div>
                <i className="fa-brands fa-instagram"></i>
              </div>
            </li>
          </a> : <div></div>}
          {superdata.data[0].youtube == 1 ? <a href={superdata.data[0].website_youtube} target="_blank" style={{ color: "#25330F",textDecoration:'none' }}>
            <li className="googleplus_social">
              <div>
                <i className="fa-brands fa-youtube"></i>
              </div>
            </li>
          </a> : <div></div>}
          {superdata.data[0].pinterest == 1 ? <a
            href={superdata.data[0].website_pinterest}
            target="_blank"
            style={{ color: "#25330F",textDecoration:'none' }}
          >
            <li className="pinterest_social">
              <div>
                <i className="fa-brands fa-pinterest-p"></i>
              </div>
            </li>
          </a> : <div></div>}
          {superdata.data[0].linkedin == 1 ?           <a
            href={superdata.data[0].website_linkedin}
            target="_blank"
            style={{ color: "#25330F",textDecoration:'none' }}
          >
            <li className="linkedin_social">
              <div>
                <i className="fa-brands fa-linkedin"></i>
              </div>
            </li>
          </a> : <div></div>}
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      ) : null}
    </div>
  );
                    }
      }
};

export default Menu;
