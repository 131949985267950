import axios from 'axios'
import React, { useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import Footer from './component/Footer'
import Header from './component/Header'
import Lastfooter from './component/Lastfooter'
import Menu from './component/Menu'
import Scrolltotop from './component/Scrolltotop'
import { useGetAllAddressReturnQuery } from './services/apifile'
import { getToken } from './services/LocalStorageSrv'

const SelectAddress = () => {
  const nav = useNavigate();
  const {id} = useParams();
  const supervalue = getToken("data").user_id;
  const userdata = useGetAllAddressReturnQuery(supervalue);
  const superuser = userdata.data
  if (userdata.isLoading === true) {
    return <div>{userdata.isError === true ? <div><h1>something went wrong try again sometime later</h1>{console.log("vieworder section 1")}</div> : <div></div>}</div>;
  } else {
  return (
    <div>
        <Header />
      <Menu />
      <Scrolltotop />
      <div className='superwrapper'>
      {superuser.status == 1 ? superuser.data[0].map((item) => (
      <div class="carding">
  <div class="card-body" style={{padding:"12px"}}>
    <h5 class="card-title">{item.name}</h5>
    <p class="card-title">{item.phone}</p>
    <p class="card-title">{item.email}</p>
    <p class="card-text" style={{margin:"0px"}}>{`${item.addressone},${item.addresstwo},${item.city},${item.state},${item.country}-${item.pincode}`}</p>
    <button type="submit" className='clorder somestyle'><NavLink to={`/selectproduct/${id}/${item.odr_id}`} className="changeton" style={{color:"white",width:"fit-content",fontSize:"17px"}} >Proceed</NavLink></button>
  </div>
</div>
    )) : <div>Something went wrong</div>}

      </div>
      <Footer />
      <Lastfooter />
    </div>
  )
  }
}

export default SelectAddress