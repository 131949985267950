import React from "react";
import { NavLink } from "react-router-dom";
import { useGetspecialofferinfoQuery } from "../services/apifile";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const Banner = () => {
  const options = {
    items:1,
    loop: true,
    autoplay: true, nav: false,
    dots:false,
    margin: 16,
    responsive:{
      920:{
        items:1
      },
      524:{
        items:1
      },
      300:{
        items:1
      },

      310:{
        items:1
      }
    }
  }
  const resp = useGetspecialofferinfoQuery()
  const superdata = resp.data
  if(resp.isLoading === true){return<div>{resp.isError === true ? <div><h1>something went wrong try again sometime later</h1>{console.log("banner section 1")}</div> : <div></div>}</div>}else{
  return (
   <div>
    <OwlCarousel className='owl-theme' {...options}>
     { resp.isLoading ? <div>  loading is here</div> :  superdata.data[0].map((item)=>
    <div className="displaybanner">
      
      <NavLink to={`/${item.banner_link}`} className="superheight">
      <img src={`https://admin.ayurherbals.co/upload/banner/${item.image}`} alt="test" className="bannerde" />
      </NavLink>
    </div>
    )}
    </OwlCarousel>
   </div>
  );
  }
};

export default Banner;
